import { LY_THEME, LY_THEME_NAME, LyHammerGestureConfig, LyTheme2, StyleRenderer } from '@alyle/ui';
import { MinimaDark, MinimaLight } from '@alyle/ui/themes/minima';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ScreenTrackingService } from '@angular/fire/analytics';
import { provideFirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { initializeApp } from 'firebase/app';
import player, { LottiePlayer } from 'lottie-web';
import { allIcons, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { SwiperModule } from 'swiper/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { HttpTokenInterceptor } from './core/interceptors/http.token.interceptor';
import { AuthService } from './core/services/auth.service';
import { AppleLoginProvider } from './modules/authentication/providers/apple-login-provider';
import { FirebaseService } from './modules/chat/services/firebase.service';
import { SharedModule } from './shared/shared.module';

export function playerFactory(): LottiePlayer {
	return player;
}
export function onAppInit(svc: AuthService): () => Promise<any> {
	return svc.initialize;
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		CoreModule,
		SharedModule,
		InfiniteScrollModule,
		SweetAlert2Module.forRoot(),
		LottieModule.forRoot({ player: playerFactory }),
		SwiperModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),

		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireAnalyticsModule,
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideFirestore(() => getFirestore()),
		SocialLoginModule,
		NgxBootstrapIconsModule.pick(allIcons),
	],
	providers: [
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							'195970246822-tvbtctioktdinhnqu8pr1uael6eji1rh.apps.googleusercontent.com',
						),
					},
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider('464194865102792'),
					},
					{
						id: AppleLoginProvider.PROVIDER_ID,
						provider: new AppleLoginProvider(
							'goodpeople.world',
							'name email',
							environment.hostUrl + 'auth/login',
						),
					},
				],
			} as SocialAuthServiceConfig,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

		LyTheme2,
		StyleRenderer,
		// Theme that will be applied to this module
		{ provide: LY_THEME_NAME, useValue: 'minima-light' },
		{ provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
		{ provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
		// Gestures
		{ provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }, // Required for <ly-carousel>
		{
			provide: APP_INITIALIZER,
			useFactory: (fs: FirebaseService) => () => fs.login(),
			deps: [FirebaseService],
			multi: true,
		},
		ScreenTrackingService,
		{
			provide: APP_INITIALIZER,
			useFactory: onAppInit,
			deps: [AuthService],
			multi: true,
		},
	],
	exports: [TranslateModule, InfiniteScrollModule],
	bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
