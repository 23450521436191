import { LY_THEME, LY_THEME_NAME, lyl, LyTheme2, StyleRenderer, ThemeRef, ThemeVariables, WithStyles } from '@alyle/ui';
import {
	ImgCropperConfig,
	ImgCropperErrorEvent,
	ImgCropperEvent,
	ImgCropperLoaderConfig,
	LyImageCropper,
	STYLES as CROPPER_STYLES,
} from '@alyle/ui/image-cropper';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import Swal from 'sweetalert2';

import { IProfile } from '../../../core/models/IProfile';
import { UserProfileService } from '../../../core/services/user-profile.service';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
	ref.renderStyleSheet(CROPPER_STYLES);
	const cropper = ref.selectorsOf(CROPPER_STYLES);

	return {
		root: lyl`{
      ${cropper.root} {
        max-width: 400px
        height: 300px
      }
    }`,
		sliderContainer: lyl`{
      text-align: center
      max-width: 400px
      margin: 14px
    }`,
		cropResult: lyl`{
      border-radius: 50%
    }`,
	};
};

@Component({
	selector: 'gpe-crop-circle',
	templateUrl: './crop-circle.component.html',
	styleUrls: ['./crop-circle.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [StyleRenderer],
})
export class CropCircleComponent implements WithStyles, AfterViewInit {
	classes = this.sRenderer.renderSheet(STYLES, 'root');
	scale = 1;
	ready = false;
	minScale = 0.1;

	@Input()
	modalRef: any;

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};
	is_loading = false;

	// @ts-ignore
	@ViewChild(LyImageCropper, { static: true }) readonly cropper: LyImageCropper;
	myConfig: ImgCropperConfig = {
		width: 256, // Default `250`
		height: 256, // Default `200`
		fill: '#a5a5a5', // Default transparent if type = png else #000
		type: 'image/png', // Or you can also use `image/jpeg`
		round: true,
	};

	constructor(
		readonly sRenderer: StyleRenderer,
		private _platform: Platform,
		private userProfileService: UserProfileService,
	) {}

	ngAfterViewInit() {}

	onCropped(e: ImgCropperEvent) {
		this.is_loading = true;
		if (e.dataURL) {
			const strImage = e.dataURL.replace(/^data:image\/[a-z]+;base64,/, '');

			this.userProfileService.changeProfilePicture(strImage).subscribe(
				(value) => {
					if (value.success && value.error.length == 0) {
						Swal.fire(
							'Successfully changed avatar!',
							'You have successfully replaced the image, it will take a few more minutes for everyone to see your new image.',
							'success',
						).then((value) => {
							this.closeModal();
							this.cropper.clean();
						});
					}
				},
				(error) => {
					Swal.fire('Something bad happened!', 'Failed to change image. Try again!', 'error').then(
						(value) => {
							this.cropper.clean();
						},
					);
				},
				() => {
					this.is_loading = false;
					this.closeModal();
				},
			);
		} else {
			Swal.fire('Something bad happened!', 'Failed to change image. Try again!', 'error').then((value) => {
				this.cropper.clean();
				this.is_loading = false;
			});
		}
	}
	onLoaded(e: ImgCropperEvent) {
		console.log('img loaded', e);
	}
	onError(e: ImgCropperErrorEvent) {
		console.warn(`'${e.name}' is not a valid image`, e);
		Swal.fire('Something bad happened!', 'Failed to change image. Try again!', 'error').then((value) => {
			this.cropper.clean();
		});
	}

	ngOnInit(): void {}

	closeModal() {
		this.modalRef.close('Close click');
	}
}
