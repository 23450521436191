import { UserService } from 'src/app/core/services/user.service';
import { Component, Input, OnInit } from '@angular/core';

import { IPost } from '../../../../../core/models/IPost';

@Component({
	selector: 'gpe-post-type-text',
	templateUrl: './post-type-text.component.html',
	styleUrls: ['./post-type-text.component.scss'],
})
export class PostTypeTextComponent implements OnInit {
	@Input()
	post: IPost | undefined = undefined;

	@Input() public likePost: ((postId: number, nextState: boolean) => void) | undefined;

	@Input()
	showCommentsClicked!: () => void;

	@Input()
	showShareClicked!: () => void;

	@Input() reportPost!: () => void;
	@Input() reportUser!: () => void;
	@Input() hidePost!: () => void;
	@Input() blockUser!: () => void;
	@Input() editPost!: () => void;
	@Input() deletePost!: () => void;
	@Input() navigateToPost!: () => void;

	myUserId: number = 0;
	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
	}
}
