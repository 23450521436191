import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
	selector: 'gpe-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
	public loadingAnimationOptions: AnimationOptions = {
		path: '/assets/lottie/100931-loading.json',
	};
}
