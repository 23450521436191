import { IInterestsTree, IInterestType } from './../../../../core/models/IInterests';
import { ICompany } from './../../../../core/models/company/ICompany';
import { InterestsService } from './../../../../core/services/interests.service';
import { UserService } from './../../../../core/services/user.service';
import { AnimationOptions } from 'ngx-lottie';
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'gpe-edit-company-inner',
	templateUrl: './edit-company-inner.component.html',
	styleUrls: ['./edit-company-inner.component.scss'],
})
export class EditCompanyInnerComponent implements OnInit {
	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	constructor(private userService: UserService, private interestsService: InterestsService) {}

	@Input()
	company!: ICompany;

	interestsLoading = false;
	interestsTree: IInterestsTree[] | null = [];

	@Input()
	interestsSelected: number[] = [];

	@Input()
	onSubmit!: () => void;

	industries: any = [
		{ id: 1, name: 'Tech' },
		{ id: 2, name: 'Health' },
		{ id: 3, name: 'Car' },
		{ id: 4, name: 'Real estate' },
	];
	companySizes: any = [
		{ id: 1, name: '1-10' },
		{ id: 2, name: '10-100' },
		{ id: 3, name: '100-1000' },
		{ id: 4, name: '1000+' },
	];
	companyTypes: any = [
		{ id: 1, name: 'Type 1' },
		{ id: 2, name: 'Type 2' },
		{ id: 3, name: 'Type 3' },
		{ id: 4, name: 'Type 4' },
	];

	ngOnInit(): void {
		this._getInterests();
	}

	private _getInterests() {
		let finished = 0;

		this.interestsLoading = true;
		if (this.company.user_id > 0) {
			this.interestsService.getInterestsBatch([this.company.user_id]).subscribe((val) => {
				if (val.result.length > 0) {
					if (val.result[0].interests && val.result[0].interests.length > 0) {
						for (let i of val.result[0].interests) {
							if (i.is_selected) {
								this.interestsSelected.push(i.interest.id);
							}
						}
					}
				}
				finished++;
				if (finished > 1) {
					this.interestsLoading = false;
				}
			});
		} else {
			finished++;
		}
		this.interestsService.getInterestsTree(IInterestType.COMPANY).subscribe((val) => {
			finished++;
			this.interestsTree = val;
			if (finished > 1) {
				this.interestsLoading = false;
			}
		});
	}

	addInterest(userId: number) {
		if (!this.interestsSelected.includes(userId)) {
			this.interestsSelected.push(userId);
		}
	}

	removeInterest(userId: number) {
		if (this.interestsSelected.includes(userId)) {
			this.interestsSelected = this.interestsSelected.filter((obj) => {
				return obj != userId;
			});
		}
	}
}
