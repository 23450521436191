<a (click)="closeModal()" class="modal-close-button mb-3">Bezár</a>
<div class="report-title">
	Report {{ reportType }} <small>({{ reportName }})</small>
</div>
<form [formGroup]="reportForm" class="confirm-form">
	<textarea
		class="form-control mt-2"
		id="reason"
		rows="5"
		placeholder="Why do you report?"
		formControlName="reason"
	></textarea>
</form>
<div class="d-flex align-items-center justify-content-between mt-3 w-100 end">
	<button class="btn btn-primary report-btn" (click)="report()">Send report</button>
	<button class="btn btn-warning cancel-btn" (click)="closeModal()">Cancel</button>
</div>
