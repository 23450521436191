import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LottieModule } from 'ngx-lottie';

import { SharedModule } from '../shared/shared.module';

import { ReportProblemComponent } from './common/report-problem/report-problem.component';
import { SuggestIdeaComponent } from './common/suggest-idea/suggest-idea.component';
import { HeaderComponent } from './header/header.component';
import { SearchBarComponent } from './header/search-bar/search-bar.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NotfoundComponent } from './notfound/components/notfound.component';

@NgModule({
	declarations: [
		HeaderComponent,
		NotfoundComponent,
		SearchBarComponent,
		MainLayoutComponent,
		ReportProblemComponent,
		SuggestIdeaComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		SharedModule,
		RouterModule,
		FormsModule,
		LottieModule,
		NgbDropdownModule,
	],
	exports: [HeaderComponent, NotfoundComponent],
})
export class CoreModule {}
