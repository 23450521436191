<ng-container [formGroup]="_formGroup">
	<!-- EMAIL -->
	<div class="form-group" *ngIf="inputType === 'email'">
		<label for="email" translate>auth.emailAddress</label>
		<input
			class="form-control"
			type="email"
			id="email"
			[formControlName]="'email'"
			[placeholder]="'auth.email' | translate"
			(focus)="onFocus()"
			(blur)="onBlur()"
			[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.email?.errors }"
		/>
		<div *ngIf="submitted && _formGroup?.controls?.email?.errors" class="invalid-feedback">
			<div *ngIf="_formGroup?.controls?.email?.errors?.required" class="error-message" translate>
				auth.emailRequired
			</div>
		</div>
		<div *ngIf="!focusInput && _formGroup?.controls?.email?.errors" class="invalid-feedback">
			<div *ngIf="_formGroup?.controls?.email?.errors?.email" class="error-message" translate>
				auth.errorValidEmailAddress
			</div>
		</div>
	</div>

	<!-- PASSWORD -->
	<div class="form-group" *ngIf="inputType === 'password'">
		<label for="password" translate>auth.password</label>
		<div class="position-relative">
			<div class="input-group">
				<input
					class="form-control password"
					[type]="hide ? 'password' : 'text'"
					id="password"
					[formControlName]="'password'"
					[placeholder]="'auth.password' | translate"
					[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.password?.errors }"
				/>
			</div>
			<span class="input-group-text-absolute">
				<i
					[ngClass]="hide ? 'fa-eye' : 'fa-eye-slash'"
					class="fa"
					(mousedown)="hide = !hide"
					(mouseup)="hide = !hide"
					aria-hidden="true"
				>
				</i>
			</span>
		</div>

		<div
			*ngIf="submitted && _formGroup?.controls?.password?.errors?.required"
			class="invalid-feedback error-message"
			translate
		>
			auth.passwordRequired
		</div>
		<div
			*ngIf="submitted && _formGroup?.controls?.password?.errors?.minlength"
			class="invalid-feedback error-message"
			translate
		>
			auth.passwordMinimumErrorMessage
		</div>
		<div
			*ngIf="submitted && _formGroup?.controls?.password?.errors?.pattern"
			class="invalid-feedback error-message"
			translate
		>
			auth.passwordPatternErrorMessage
		</div>
	</div>

	<!-- PASSWORD CONFIRM -->
	<div class="form-group" *ngIf="inputType === 'passwordAgain'">
		<label for="passwordAgain" translate>auth.passwordAgain</label>
		<div class="position-relative">
			<div class="input-group">
				<input
					class="form-control password"
					[type]="hide ? 'password' : 'text'"
					id="passwordAgain"
					[formControlName]="'passwordAgain'"
					[placeholder]="'auth.password' | translate"
					[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.passwordAgain?.errors }"
					(blur)="displayPassConfError = true"
				/>
			</div>
			<span class="input-group-text-absolute">
				<i
					[ngClass]="hide ? 'fa-eye' : 'fa-eye-slash'"
					class="fa"
					(mousedown)="hide = !hide"
					(mouseup)="hide = !hide"
					aria-hidden="true"
				>
				</i>
			</span>
		</div>
		<div
			*ngIf="submitted && _formGroup?.controls?.passwordAgain?.errors?.required"
			class="invalid-feedback error-message"
			translate
		>
			auth.passwordRequired
		</div>
		<div
			*ngIf="
				displayPassConfError &&
				_formGroup?.controls?.passwordAgain?.value != _formGroup?.controls?.password?.value
			"
			class="invalid-feedback error-message"
			translate
		>
			auth.passwordMatchError
		</div>
	</div>

	<!-- FIRST NAME -->
	<div *ngIf="inputType === 'firstName'" class="form-group">
		<label for="firstName" translate>auth.firstName</label>
		<input
			type="text"
			id="firstName"
			[formControlName]="'firstName'"
			[placeholder]="'auth.firstName' | translate"
			class="form-control"
			[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.firstName?.errors }"
		/>
		<div *ngIf="submitted && _formGroup?.controls?.firstName?.errors" class="invalid-feedback">
			<div *ngIf="_formGroup?.controls?.firstName?.errors?.required" class="error-message" translate>
				auth.firstNameRequired
			</div>
		</div>
	</div>

	<!-- LAST NAME -->
	<div *ngIf="inputType === 'lastName'" class="form-group">
		<label for="lastName" translate>auth.lastName</label>
		<input
			type="text"
			id="lastName"
			[formControlName]="'lastName'"
			[placeholder]="'auth.lastName' | translate"
			class="form-control"
			[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.lastName?.errors }"
		/>
		<div *ngIf="submitted && _formGroup?.controls?.lastName?.errors" class="invalid-feedback">
			<div *ngIf="_formGroup?.value?.lastName?.errors?.required" class="error-message" translate>
				auth.lastNameRequired
			</div>
		</div>
	</div>

	<!-- BIRTHDATE -->
	<div *ngIf="inputType === 'birthDate'" class="form-group">
		<label for="birthDate" translate>auth.birthDate</label>
		<input
			type="date"
			id="birthDate"
			[formControlName]="'birthDate'"
			placeholder="auth.birthDateFormat"
			max="2999-12-31"
			class="form-control"
			[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.birthDate?.errors }"
		/>
		<div class="invalid-feedback">
			<div *ngIf="getAge(_formGroup?.controls?.birthDate?.value) < 14" class="error-message" translate>
				auth.birthDateErrorMessage
			</div>
		</div>
	</div>

	<!-- SEX -->
	<!-- TODO jobb megoldást találni erre -->
	<div *ngIf="inputType === 'sex'" class="form-group">
		<label for="sex" translate>auth.sex</label>

		<div class="sex-picker" [ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.sex?.errors }">
			<!-- <input type="button" class="btn btn-light" data-toggle="button" aria-pressed="false" autocomplete="off" value="{{'auth.male' | translate}}"> -->

			<input type="hidden" id="sex" [formControlName]="'sex'" autocomplete="disabled" class="form-control" />

			<input
				type="button"
				class="form-control btn btn-light"
				(click)="maleButtonClick()"
				[ngClass]="maleClicked ? 'focus' : 'danger'"
				value="{{ 'auth.male' | translate }}"
			/>
			<input
				type="button"
				class="form-control btn btn-light"
				(click)="femaleButtonClick()"
				[ngClass]="femaleClicked ? 'focus' : 'danger'"
				value="{{ 'auth.female' | translate }}"
			/>
			<input
				type="button"
				class="form-control btn btn-light"
				(click)="otherButtonClick()"
				[ngClass]="otherClicked ? 'focus' : 'danger'"
				value="{{ 'auth.other' | translate }}"
			/>
		</div>
	</div>

	<!-- CITY -->
	<!-- TODO add backend auto complete -->
	<div *ngIf="inputType === 'city'" class="form-group">
		<div class="position-relative">
			<label for="city" translate>auth.city</label>
			<input type="hidden" id="cityId" [formControlName]="'cityId'" />

			<input
				type="text"
				id="city"
				[formControlName]="'city'"
				[placeholder]="'auth.city' | translate"
				autocomplete="disabled"
				class="form-control"
				[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.city?.errors }"
				(input)="cityInput()"
			/>

			<div class="selector-holder" *ngIf="searchSelectorOpen">
				<div *ngIf="!is_loading && cityResult.length == 0">auth.selector.not_have_result</div>
				<div class="result-row" *ngFor="let res of cityResult" (click)="selectCity(res)">
					{{ res.city_name + ', ' + res.country_name }}
				</div>

				<div *ngIf="is_loading" class="text-center mt-2">
					<div class="loading-animation-container">
						<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
					</div>
				</div>
			</div>

			<div *ngIf="submitted && _formGroup?.controls?.city?.errors" class="invalid-feedback">
				<div *ngIf="_formGroup?.controls?.city?.errors?.required" class="error-message" translate>
					auth.cityRequired
				</div>
			</div>
		</div>
	</div>

	<!-- COUNTRY -->
	<!-- TODO add backend auto complete -->
	<div *ngIf="inputType === 'country'" class="form-group">
		<div class="position-relative">
			<label for="city" translate>auth.country</label>
			<input type="hidden" id="countryId" [formControlName]="'countryId'" />

			<input
				type="text"
				id="country"
				[formControlName]="'country'"
				[placeholder]="'auth.country' | translate"
				class="form-control"
				[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.country?.errors }"
				autocomplete="disabled"
				(input)="countryInput()"
			/>
			<div class="selector-holder" *ngIf="searchSelectorOpen">
				<div *ngIf="!is_loading && countryResult.length == 0">auth.selector.not_have_result</div>
				<div class="result-row" *ngFor="let res of countryResult" (click)="selectCountry(res)">
					{{ res.country_name }}
				</div>

				<div *ngIf="is_loading" class="text-center mt-2">
					<div class="loading-animation-container">
						<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
					</div>
				</div>
			</div>
			<div *ngIf="submitted && _formGroup?.controls?.country?.errors" class="invalid-feedback">
				<div *ngIf="_formGroup?.controls?.country?.errors?.required" class="error-message" translate>
					auth.countryRequired
				</div>
			</div>
		</div>
	</div>

	<!-- LANGUAGE -->
	<div *ngIf="inputType === 'language'" class="form-group">
		<label translate>auth.language</label>
		<select
			[formControlName]="'language'"
			class="form-control"
			style="width: 100%"
			tabindex="-1"
			aria-hidden="true"
			[ngClass]="{ 'is-invalid': submitted && _formGroup?.controls?.language?.errors }"
		>
			<option *ngFor="let language of languageList" [ngValue]="language.id">
				{{ language.name | translate }}
			</option>
		</select>
		<div *ngIf="submitted && _formGroup?.controls?.language?.errors" class="invalid-feedback">
			<div *ngIf="_formGroup?.controls?.language?.errors?.required" class="error-message" translate>
				auth.languageRequired
			</div>
		</div>
	</div>
</ng-container>
