<ng-container *ngIf="post">
	<div class="d-flex" *ngIf="firstReplyInput && !is_loading">
		<img [src]="myUser.data.user_avatar" *ngIf="myUser" class="avatar-image" />

		<div class="input-group comment-input-box">
			<gpe-complex-text-input class="comment-input" [contentId]="0"></gpe-complex-text-input>
			<a class="btn btn-secondary comment-input-btn" (click)="addNewComment(0)">
				<img src="assets/icons/send-green.svg" alt="Send button icon" />
			</a>
		</div>
	</div>

	<h4 class="mb-3">Comments ({{ post.post_comment_count }})</h4>

	<ng-container *ngFor="let comment of comments">
		<gpe-comment-item [comment]="comment" [mainComment]="true" [userFollowing]="userFollowing"></gpe-comment-item>
	</ng-container>

	<div
		*ngIf="post.post_comment_count > 15 && hasMorePage && !is_loading"
		class="show-more-comment cursor-pointer"
		(click)="loadNextPage()"
	>
		További kommentek betöltése
	</div>

	<div *ngIf="is_loading" class="text-center mt-2">
		<div class="loading-animation-container">
			<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
		</div>
	</div>

	<div class="d-flex" *ngIf="!firstReplyInput && !is_loading">
		<img [src]="myUser.data.user_avatar" *ngIf="myUser" class="avatar-image" />

		<div class="input-group comment-input-box">
			<gpe-complex-text-input class="comment-input" [contentId]="0"></gpe-complex-text-input>
			<a class="btn btn-secondary comment-input-btn" (click)="addNewComment(0)">
				<img src="assets/icons/send-green.svg" alt="Send button icon" />
			</a>
		</div>
	</div>
</ng-container>
