<a (click)="closeModal()" class="modal-close-button">X</a>

<div>
	<ng-container *ngIf="!is_loading">
		<div class="mainbox">
			<div class="leftbar">
				<div *ngIf="profile" class="avatar-image-holder">
					<div
						class="avatar-image cursor-pointer"
						[style.background-image]="'url(' + profile.data.user_avatar + ')'"
					></div>
				</div>
				<a
					*ngIf="this.postId == 0 || activeTab === 'event'"
					class="btn btn-primary btn-block"
					[class.btn-active]="activeTab === 'event'"
					(click)="setTab('event')"
				>
					<img src="assets/icons/event.svg" /> Event
				</a>
				<a
					class="btn btn-primary btn-block"
					[class.btn-active]="activeTab === 'post'"
					(click)="setTab('post')"
					*ngIf="this.postId == 0 || activeTab === 'post'"
				>
					<img src="assets/icons/post.svg" /> Post
				</a>
				<a
					class="btn btn-primary btn-block"
					[class.btn-active]="activeTab === 'text'"
					(click)="setTab('text')"
					*ngIf="this.postId == 0 || activeTab === 'text'"
				>
					<img src="assets/icons/text.svg" /> Text
				</a>
				<a
					class="btn btn-primary btn-block"
					[class.btn-active]="activeTab === 'voice'"
					(click)="setTab('voice')"
					*ngIf="this.postId == 0 || activeTab === 'voice'"
				>
					<img src="assets/icons/sound.svg" /> Voice
				</a>
			</div>
			<div class="rightbar">
				<ng-container *ngIf="activeTab === 'event'">
					<gpe-create-post-event
						[bgColors]="bgColors"
						[closeModal]="closeModal"
						[postId]="postId"
						[post]="post"
						[is_loading]="is_loading"
						[uploadAttachments]="uploadAttachments"
					></gpe-create-post-event>
				</ng-container>
				<ng-container *ngIf="activeTab === 'post'">
					<gpe-create-post-post
						[bgColors]="bgColors"
						[closeModal]="closeModal"
						[postId]="postId"
						[post]="post"
						[is_loading]="is_loading"
						[uploadAttachments]="uploadAttachments"
					></gpe-create-post-post>
				</ng-container>
				<ng-container *ngIf="activeTab === 'text'">
					<gpe-create-post-text
						[bgColors]="bgColors"
						[closeModal]="closeModal"
						[postId]="postId"
						[post]="post"
						[is_loading]="is_loading"
					></gpe-create-post-text>
				</ng-container>
				<ng-container *ngIf="activeTab === 'voice'">
					<gpe-create-post-voice
						[bgColors]="bgColors"
						[closeModal]="closeModal"
						[is_loading]="is_loading"
						[postId]="postId"
						[post]="post"
						[uploadAttachments]="uploadAttachments"
					></gpe-create-post-voice>
				</ng-container>
			</div>
		</div>
	</ng-container>

	<div *ngIf="is_loading" class="text-center mt-5 mb-5">
		<div class="loading-animation-container">
			<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
		</div>
	</div>
</div>
