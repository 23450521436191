<div class="card post">
	<div class="post-background">
		<swiper
			[loop]="false"
			[autoHeight]="false"
			[allowTouchMove]="false"
			[autoplay]="{ delay: 3000, disableOnInteraction: true }"
			[pagination]="false"
		>
			<ng-template swiperSlide *ngFor="let media of post?.medias">
				<ng-container *ngIf="media.type == PostMediaType.Image">
					<div [style.background-image]="'url(' + media.picture.file + ')'" class="bg-image-blur"></div>
					<div class="post-media-image" [style.background-image]="'url(' + media.picture.file + ')'"></div>
				</ng-container>

				<div *ngIf="media.type == PostMediaType.Video">
					<div [style.background-image]="'url(' + media.video.thumbnail + ')'" class="bg-image-blur"></div>
					<div class="post-media-image">
						<video class="post-media-video" [poster]="media.video.thumbnail" height="300" controls>
							<source
								[src]="
									media.video.compatibility_url && media.video.compatibility_url != ''
										? media.video.compatibility_url
										: media.video.file
								"
								type="video/mp4"
							/>
						</video>
					</div>
				</div>
			</ng-template>
		</swiper>
	</div>
	<div class="row">
		<div class="col-10">
			<div class="post-content">
				<h3 class="cursor-pointer" (click)="navigateToPost()">EVENT</h3>
				<p>
					{{ post?.post_event_title }}
				</p>
			</div>
		</div>
		<div class="col-2 post-action-buttons">
			<div class="post-action-button cursor-pointer">
				<img src="assets/icons/hand.svg" />
				{{ post?.post_like_count }}
			</div>
			<div class="post-action-button cursor-pointer" (click)="showCommentsClicked()">
				<img src="assets/icons/comment.svg" />
				{{ post?.post_comment_count }}
			</div>
			<div class="post-action-button cursor-pointer" (click)="showShareClicked()">
				<img src="assets/icons/share.svg" />
				Share
			</div>
			<div ngbDropdown class="post-action-button">
				<div ngbDropdownToggle class="cursor-pointer" id="dropdownMore1">
					<img src="assets/icons/more.svg" />
					More
				</div>
				<div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMore1">
					<ng-container *ngIf="post?.post_user_id != myUserId">
						<button ngbDropdownItem (click)="reportPost()">
							<img src="assets/icons/report-user.svg" height="14" class="float-start" />Report post
						</button>
						<button ngbDropdownItem (click)="reportUser()">
							<img src="assets/icons/report-user.svg" height="14" class="float-start" />Report user
						</button>
						<button ngbDropdownItem (click)="hidePost()">
							<img src="assets/icons/fi_eye-off.svg" height="14" class="float-start" />Hide post
						</button>
						<button ngbDropdownItem (click)="blockUser()">
							<img src="assets/icons/block-user.svg" height="14" class="float-start" />Block user
						</button>
					</ng-container>
					<ng-container *ngIf="post?.post_user_id == myUserId">
						<button ngbDropdownItem (click)="editPost()">
							<img src="assets/icons/edit-room.svg" height="14" class="float-start" />Edit post
						</button>
						<button ngbDropdownItem (click)="deletePost()">
							<img src="assets/icons/remove-room-list.svg" height="14" class="float-start" />Delete post
						</button>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="event-footer">
		<div class="event-footer-inner">
			<div class="item"><span>Going: </span> {{ post?.joined_members?.approved }}</div>
			<div class="item"><span>Maybe: </span> {{ post?.joined_members?.interested }}</div>
			<div class="item" *ngIf="post && post.post_event_max_people > 0">
				<span>People limit: </span> {{ post?.post_event_max_people }}
			</div>
			<div class="item">
				<span>Date: </span> {{ post?.post_event_date_from_epoch | date: 'y.MMMM.d' | lowercase }}-{{
					post?.post_event_date_to_epoch | date: 'y.MMMM.d' | lowercase
				}}
			</div>
			<div class="item" *ngIf="post && post.location_id > 0">
				<span>Location: </span> {{ post?.location_title }}
			</div>
			<!--
			<div class="item flex-grow-0 text-right" *ngIf="post && post.post_user_id != myUserId ">
				AREYOUJOIN
			</div>-->
		</div>
	</div>
</div>
