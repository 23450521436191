export interface IInterests {
	id: number;
	category_id: number;
	icon_black: string;
	icon_white: string;
	name: string;
	order: number;
	deleted: boolean;
}
export interface IInterestsStatus {
	interest: IInterests;
	is_selected: boolean;
	selected_date: number;
}

export interface IInterestsBatchGet {
	result: {
		id: number;
		interests: IInterestsStatus[];
	}[];
}

export interface IInterestsCategory {
	id: number;
	name: string;
	order: number;
	deleted: boolean;
}

export interface IInterestsTree extends IInterestsCategory {
	items: IInterests[];
}
export interface IInterestsTreeGet {
	interests: IInterestsTree[];
}
export enum IInterestType {
	USER,
	COMPANY,
}
