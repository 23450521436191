import { Injectable } from '@angular/core';
import {
	collection,
	collectionData,
	collectionSnapshots,
	doc,
	docData,
	DocumentData,
	documentId,
	DocumentReference,
	Firestore,
	FirestoreDataConverter,
	limit,
	limitToLast,
	orderBy,
	query,
	runTransaction,
	serverTimestamp,
	Transaction,
	where,
} from '@angular/fire/firestore';
import { deleteDoc } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IChatMessage, IChatReaction } from 'src/app/core/models/firebasechat/IChatMessage';
import { UserService } from 'src/app/core/services/user.service';
import { QueryParams } from 'src/app/modules/chat/services/firebase.service';
import { environment } from 'src/environments/environment';

import { CreateRoomModel, Room } from '../models/room.dto';
import { RoomViewModel } from '../models/room.view-model';
import { RoomUser } from '../models/room-user.dto';

@Injectable({
	providedIn: 'root',
})
export class FirebaseRoomService {
	constructor(private fireStore: Firestore, private userService: UserService) {}

	private get currentUserId(): number {
		return +this.userService.getLoginedUserId();
	}

	private roomUsersCollection = collection(this.fireStore, environment.firestoreChat.collectionKeys.roomUsers);
	private roomsCollection = collection(this.fireStore, environment.firestoreChat.collectionKeys.rooms);

	public getRecommendedRooms(pageSize: number): Observable<Room[]> {
		return this.getRoomUser().pipe(
			switchMap((user) =>
				collectionData(
					query(
						this.roomsCollection.withConverter<Room>(this.roomConverter),
						where(
							documentId(),
							'not-in',
							(user.interestedRooms ?? []).length > 0 ? user.interestedRooms : ['0'],
						),
						where(environment.firestoreChat.propertyKeys.roomIsPrivate, QueryParams.Equals, false),
						// orderBy(environment.firestoreChat.propertyKeys.roomNumberOfUsersInRoom, QueryParams.Descending),
						limit(pageSize),
					),
				),
			),
		);
	}

	public searchRooms(searchTerm: string): Observable<Room[]> {
		return collectionData(
			query(
				this.roomsCollection.withConverter<Room>(this.roomConverter),
				where(environment.firestoreChat.propertyKeys.roomIsPrivate, QueryParams.Equals, false),
				where(environment.firestoreChat.propertyKeys.roomName, QueryParams.LessOrEquals, searchTerm),
				where(
					environment.firestoreChat.propertyKeys.roomName,
					QueryParams.GreaterOrEquals,
					searchTerm + '\uf8ff',
				),
				limit(12),
			),
		);
	}

	public getRoomUser(): Observable<RoomUser> {
		return docData(
			doc(
				this.roomUsersCollection.withConverter<RoomUser>(this.roomUserConverter),
				this.currentUserId.toString(),
			),
		);
	}

	public async createUser(): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const userRef = doc(
				this.roomUsersCollection.withConverter<RoomUser>(this.roomUserConverter),
				this.currentUserId.toString(),
			);
			const userData = (await transaction.get(userRef)).data();

			if (userData) {
				return;
			} else {
				this.createUserInTransaction(transaction);
			}
		});
	}

	public getAllRooms(searchTerm = ''): Observable<Room[]> {
		return this.getRoomUser().pipe(
			switchMap((user) => this.queryRooms(user, user.interestedRooms ?? [], searchTerm)),
		);
	}

	public getFavoriteRooms(): Observable<Room[]> {
		return this.getRoomUser().pipe(switchMap((user) => this.queryRooms(user, user.favoriteRooms ?? [])));
	}

	public getOwnedRooms(): Observable<Room[]> {
		return this.getRoomUser().pipe(switchMap((user) => this.queryRooms(user, user.ownedRooms ?? [])));
	}

	public getRoom(roomId: string): Observable<Room> {
		return docData(doc(this.roomsCollection.withConverter<Room>(this.roomConverter), roomId));
	}

	public getMessagesSnapShot(roomId: string, limit: number): Observable<IChatMessage[]> {
		return collectionSnapshots(
			query(
				collection(
					this.fireStore,
					environment.firestoreChat.collectionKeys.rooms,
					roomId,
					environment.firestoreChat.collectionKeys.messages,
				),
				orderBy(environment.firestoreChat.propertyKeys.chatMessageServerTimeStamp, QueryParams.Ascending),
				limitToLast(limit),
			),
		).pipe(
			map((actions) => {
				return actions.map((r) => {
					const message = { ...r.data() } as IChatMessage;
					message.id = r.id;
					message.isSender = message.userId === this.currentUserId;
					return message;
				});
			}),
		);
	}

	public async addRoom(newRoom: CreateRoomModel): Promise<string> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const userRef = doc(
				this.roomUsersCollection.withConverter<RoomUser>(this.roomUserConverter),
				this.currentUserId.toString(),
			);
			const userData = (await transaction.get(userRef)).data();

			const newRoomRef = this.addRoomInTransaction(transaction, newRoom);

			if (userData) {
				this.markRoomOwnedInTransaction(transaction, userRef, userData, newRoomRef.id, true);
			}

			this.updateUserInTransaction(transaction);

			return newRoomRef.id;
		});
	}

	public async updateRoom(updateRoom: RoomViewModel): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const roomRef = doc(this.roomsCollection.withConverter<Room>(this.roomConverter), updateRoom.id);

			this.updateRoomInTransaction(transaction, roomRef, updateRoom);

			this.updateUserInTransaction(transaction);
		});
	}

	public async deleteRoom(roomId: string): Promise<void> {
		return await deleteDoc(doc(this.fireStore, environment.firestoreChat.collectionKeys.rooms, roomId));
	}

	public async markRoomInterested(roomId: string, add: boolean): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const userRef = doc(
				this.roomUsersCollection.withConverter<RoomUser>(this.roomUserConverter),
				this.currentUserId.toString(),
			);
			const roomRef = doc(this.roomsCollection.withConverter<Room>(this.roomConverter), roomId);
			const userData = (await transaction.get(userRef)).data();
			const roomData = (await transaction.get(roomRef)).data();

			if (userData) {
				this.markRoomInterestedInTransaction(transaction, userRef, userData, roomId, add);

				if (!add) {
					this.markRoomFavoriteInTransaction(transaction, userRef, userData, roomId, add);
					if (roomData) {
						this.removeUserFromRoomInTransaction(transaction, roomRef, roomData, this.currentUserId);
					}
				}
			}

			this.updateUserInTransaction(transaction);
		});
	}

	public async markRoomFavorite(roomId: string, add: boolean): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const userRef = doc(
				this.roomUsersCollection.withConverter<RoomUser>(this.roomUserConverter),
				this.currentUserId.toString(),
			);
			const userData = (await transaction.get(userRef)).data();

			if (userData) {
				if (add) {
					this.markRoomInterestedInTransaction(transaction, userRef, userData, roomId, add);
				}

				this.markRoomFavoriteInTransaction(transaction, userRef, userData, roomId, add);
			}

			this.updateUserInTransaction(transaction);
		});
	}

	public async enterRoom(roomId: string): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const roomRef = doc(this.roomsCollection.withConverter<Room>(this.roomConverter), roomId);
			const roomData = (await transaction.get(roomRef)).data();

			if (roomData) {
				this.addUserToRoomInTransaction(transaction, roomRef, roomData, false);
			}

			this.updateUserInTransaction(transaction);
		});
	}

	public async enterRoomAnonymous(roomId: string): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const roomRef = doc(this.roomsCollection.withConverter<Room>(this.roomConverter), roomId);
			const roomData = (await transaction.get(roomRef)).data();

			if (roomData) {
				this.addUserToRoomInTransaction(transaction, roomRef, roomData, true);
			}

			this.updateUserInTransaction(transaction);
		});
	}

	public async leaveRoom(roomId: string): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const roomRef = doc(this.roomsCollection.withConverter<Room>(this.roomConverter), roomId);

			const roomData = (await transaction.get(roomRef)).data();

			if (roomData) {
				this.removeUserFromRoomInTransaction(transaction, roomRef, roomData, this.currentUserId);
			}

			this.updateUserInTransaction(transaction);
		});
	}

	public async sendMessage(
		roomId: string,
		message: string,
		isAnonymous: boolean,
		originalMessage?: IChatMessage,
	): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const roomRef = doc(this.roomsCollection, roomId);

			this.addMessageInTransaction(transaction, roomRef, message, isAnonymous, originalMessage);

			this.updateUserInTransaction(transaction);
		});
	}

	public async reactToMessage(roomId: string, messageId: string, reaction: IChatReaction): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const messageRef = doc(
				this.roomsCollection,
				roomId,
				environment.firestoreChat.collectionKeys.messages,
				messageId,
			).withConverter(this.messageConverter);
			const messageData = (await transaction.get(messageRef)).data();

			if (messageData) {
				this.reactToMessageInTransaction(transaction, messageRef, messageData, reaction);
			}

			this.updateUserInTransaction(transaction);
		});
	}

	public async kickUser(roomId: string, userId: number): Promise<void> {
		return await runTransaction(this.fireStore, async (transaction) => {
			const roomRef = doc(this.roomsCollection.withConverter<Room>(this.roomConverter), roomId);

			const roomData = (await transaction.get(roomRef)).data();

			if (roomData) {
				this.removeUserFromRoomInTransaction(transaction, roomRef, roomData, userId);

				this.addUserToKickedUsersInTransaction(transaction, roomRef, roomData, userId);
			}

			this.updateUserInTransaction(transaction);
		});
	}

	public getInvites(): Observable<Room[]> {
		return collectionData(
			query(
				this.roomsCollection.withConverter<Room>(this.roomConverter),
				where(
					environment.firestoreChat.propertyKeys.roomInvites,
					QueryParams.ArrayContains,
					this.currentUserId,
				),
			),
		);
	}

	private queryRooms(user: RoomUser, roomIds: string[], searchTerm = ''): Observable<Room[]> {
		return collectionData(
			query(
				this.roomsCollection.withConverter<Room>(this.roomConverter),
				where(documentId(), 'in', roomIds.length > 0 ? roomIds : ['0']),
			),
		).pipe(
			map((rooms) => rooms.filter((r) => r.name.toLowerCase().includes(searchTerm.toLowerCase()))),
			map((rooms) =>
				rooms.map((r) => {
					r.isInterested = (user.interestedRooms ?? []).includes(r.id);
					r.isFavorite = (user.favoriteRooms ?? []).includes(r.id);
					r.isOwned = (user.ownedRooms ?? []).includes(r.id);
					r.isSelected = r.userIds.includes(this.currentUserId);
					return r;
				}),
			),
		);
	}

	private markRoomInterestedInTransaction(
		transaction: Transaction,
		userRef: DocumentReference<RoomUser>,
		userData: RoomUser,
		roomId: string,
		add: boolean,
	) {
		let interestedRooms = userData.interestedRooms ?? [];
		if (add && !interestedRooms.includes(roomId)) {
			interestedRooms.push(roomId);
		} else if (!add && interestedRooms.includes(roomId)) {
			interestedRooms = interestedRooms.filter((x) => x != roomId);
		}

		console.log('InterestedRooms: ', interestedRooms);

		transaction.update(userRef, {
			interestedRooms: interestedRooms,
		});
	}

	private markRoomFavoriteInTransaction(
		transaction: Transaction,
		userRef: DocumentReference<RoomUser>,
		userData: RoomUser,
		roomId: string,
		add: boolean,
	) {
		let favoriteRooms = userData.favoriteRooms ?? [];
		if (add && !favoriteRooms.includes(roomId)) {
			favoriteRooms.push(roomId);
		} else if (!add && favoriteRooms.includes(roomId)) {
			favoriteRooms = favoriteRooms.filter((x) => x != roomId);
		}

		transaction.update(userRef, {
			favoriteRooms: favoriteRooms,
		});
	}

	private markRoomOwnedInTransaction(
		transaction: Transaction,
		userRef: DocumentReference<RoomUser>,
		userData: RoomUser,
		roomId: string,
		add: boolean,
	) {
		let ownedRooms = userData.ownedRooms ?? [];
		if (add && !ownedRooms.includes(roomId)) {
			ownedRooms.push(roomId);
		} else if (!add && ownedRooms.includes(roomId)) {
			ownedRooms = ownedRooms.filter((x) => x != roomId);
		}

		transaction.update(userRef, {
			ownedRooms: ownedRooms,
		});
	}

	private addUserToRoomInTransaction(
		transaction: Transaction,
		roomRef: DocumentReference<Room>,
		roomData: Room,
		anonymous: boolean,
	) {
		const usersInRoom = roomData.userIds ?? [];
		const anonymousUsersInRoom = roomData.anonymousUserIds ?? [];
		if (anonymous) {
			if (!anonymousUsersInRoom.includes(this.currentUserId)) {
				anonymousUsersInRoom.push(this.currentUserId);

				transaction.update(roomRef, {
					anonymousUserIds: anonymousUsersInRoom,
					userIdsLength: usersInRoom.length + anonymousUsersInRoom.length,
				});
			}
		} else {
			if (!usersInRoom.includes(this.currentUserId)) {
				usersInRoom.push(this.currentUserId);
				transaction.update(roomRef, {
					userIds: usersInRoom,
					userIdsLength: usersInRoom.length + anonymousUsersInRoom.length,
				});
			}
		}
	}

	private removeUserFromRoomInTransaction(
		transaction: Transaction,
		roomRef: DocumentReference<Room>,
		roomData: Room,
		userId: number,
	) {
		let usersInRoom = roomData.userIds ?? [];
		let anonymousUsersInRoom = roomData.anonymousUserIds ?? [];
		if (usersInRoom.includes(userId) || anonymousUsersInRoom.includes(userId)) {
			usersInRoom = usersInRoom.filter((x) => x != userId);
			anonymousUsersInRoom = anonymousUsersInRoom.filter((x) => x != userId);

			transaction.update(roomRef, {
				userIds: usersInRoom,
				anonymousUserIds: anonymousUsersInRoom,
				userIdsLength: usersInRoom.length + anonymousUsersInRoom.length,
			});
		}
	}

	private addUserToKickedUsersInTransaction(
		transaction: Transaction,
		roomRef: DocumentReference<Room>,
		roomData: Room,
		userId: number,
	) {
		const kickedUsers = roomData.kickedUserIds ?? [];
		if (!kickedUsers.includes(userId)) {
			kickedUsers.push(userId);

			transaction.update(roomRef, {
				kickedUserIds: kickedUsers,
			});
		}
	}

	private addRoomInTransaction(transaction: Transaction, newRoom: CreateRoomModel): DocumentReference<DocumentData> {
		const newRoomRef = doc(this.roomsCollection);

		const room = {
			name: newRoom.name,
			description: newRoom.description,
			isPrivate: newRoom.isPrivate,
			ownerId: this.currentUserId,
			userIds: [this.currentUserId],
			userIdsLength: 1,
			users: `${this.currentUserId}`,
		} as Room;
		room.kickedUserIds = [];
		room.anonymousUserIds = [];
		if (newRoom.coverUrl) {
			room.coverUrl = newRoom.coverUrl;
		}
		if (newRoom.invitedUserIds) {
			room.invitedUserIds = newRoom.invitedUserIds;
		}

		transaction.set(newRoomRef, room);
		return newRoomRef;
	}

	private updateRoomInTransaction(
		transaction: Transaction,
		roomRef: DocumentReference<Room>,
		updatedRoom: RoomViewModel,
	) {
		const room = {
			name: updatedRoom.name,
			description: updatedRoom.description,
			coverUrl: updatedRoom.coverUrl,
			invitedUserIds: updatedRoom.invitedUserIds,
		} as Room;

		transaction.update(roomRef, room);
	}

	private addMessageInTransaction(
		transaction: Transaction,
		roomRef: DocumentReference<DocumentData>,
		message: string,
		isAnonymous: boolean,
		reply?: IChatMessage,
	): DocumentReference<DocumentData> {
		const newMessage = {
			clientTimestamp: new Date().getTime(),
			message: message,
			serverTimestampInSec: serverTimestamp(),
			userId: this.currentUserId,
			isAnonymous: isAnonymous,
		} as IChatMessage;

		if (reply) {
			newMessage.reply = reply;
		}

		const newMessageRef = doc(collection(roomRef, environment.firestoreChat.collectionKeys.messages));
		transaction.set(newMessageRef, newMessage);
		return newMessageRef;
	}

	private reactToMessageInTransaction(
		transaction: Transaction,
		messageRef: DocumentReference<DocumentData>,
		messageData: IChatMessage,
		reaction: IChatReaction,
	) {
		let reactions = messageData.reactions ?? [];
		if (!reactions.some((r) => r.userId == reaction.userId)) {
			reactions.push(reaction);
		} else {
			reactions = reactions.filter((x) => x.userId !== reaction.userId);
		}

		transaction.update(messageRef, { reactions: reactions });
	}

	private updateUserInTransaction(transaction: Transaction) {
		transaction.update(doc(this.roomUsersCollection, this.currentUserId.toString()), {
			uid: this.currentUserId.toString(),
			lastAction: serverTimestamp(),
		});
	}

	private createUserInTransaction(transaction: Transaction) {
		transaction.set(doc(this.roomUsersCollection, this.currentUserId.toString()), {
			uid: this.currentUserId,
			lastAction: serverTimestamp(),
		});
	}

	private readonly messageConverter: FirestoreDataConverter<IChatMessage> = {
		fromFirestore: (snapshot) => {
			const message = { ...snapshot.data() } as IChatMessage;
			return message;
		},
		toFirestore: (r: any) => r,
	};

	private readonly roomConverter: FirestoreDataConverter<Room> = {
		fromFirestore: (snapshot) => {
			const room = { ...snapshot.data() } as Room;
			room.id = snapshot.id;
			return room;
		},
		toFirestore: (r: any) => r,
	};

	private readonly roomUserConverter: FirestoreDataConverter<RoomUser> = {
		fromFirestore: (snapshot) => {
			const room = { ...snapshot.data() } as RoomUser;
			return room;
		},
		toFirestore: (r: any) => r,
	};
}
