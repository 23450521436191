<div class="user-selector" *ngIf="result">
	<div class="user-selector-input-box">
		<input
			type="text"
			class="form-control"
			[(ngModel)]="searchInput"
			(input)="inputChanged()"
			(keydown)="inputKeyDown($event)"
			#eventSelectorInput
		/>
		<a class="cursor-pointer" (click)="inputChanged()"><img src="assets/icons/search-green.svg" /></a>
	</div>
	<div class="user-selector-results">
		<div class="norestext" *ngIf="!is_loading && result.length == 0 && searchInput.length > 2">No result.</div>
		<div class="norestext" *ngIf="!is_loading && result.length == 0 && searchInput.length <= 2">
			Start typing minimum 3 character to search event.
		</div>

		<div *ngIf="is_loading" class="text-center mt-2">
			<div class="loading-animation-container-sm">
				<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
			</div>
		</div>
		<ng-container *ngIf="!is_loading">
			<!-- selected users -->
			<ng-container *ngIf="eventSelected">
				<div class="result-row">
					<div class="result-row-image">
						<!-- //TODO: Which image to show ? -->
						<img [src]="eventSelected.user_data_avatar" />
					</div>
					<div class="result-row-name">
						<div class="result-row-name-content">
							{{ eventSelected.post_event_title | truncate: [23, '...'] }}<br />
							<small
								>{{ eventSelected.post_event_date_from_epoch | date }} -
								{{ eventSelected.post_event_date_to_epoch | date }}</small
							>
						</div>
					</div>
					<div class="result-row-btn" *ngIf="result">
						<a
							class="btn btn-primary m-0"
							*ngIf="!isEventAdded(eventSelected.post_id)"
							(click)="addEvent(eventSelected)"
							>{{ buttonActivateText }}</a
						>
						<a
							class="btn btn-danger m-0"
							*ngIf="isEventAdded(eventSelected.post_id)"
							(click)="removeEvent(eventSelected.post_id)"
							>{{ buttonDeactivateText }}</a
						>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="!eventSelected">
				<!-- select user -->
				<div class="result-row" *ngFor="let item of result">
					<div class="result-row-image">
						<!-- //TODO: Which image to show ? -->
						<img [src]="item.user_data_avatar" />
					</div>
					<div class="result-row-name">
						<div class="result-row-name-content">
							{{ item.post_event_title | truncate: [23, '...'] }}<br />
							<small
								>{{ item.post_event_date_from_epoch | date }} -
								{{ item.post_event_date_to_epoch | date }}</small
							>
						</div>
					</div>
					<div class="result-row-btn" *ngIf="result">
						<a class="btn btn-primary m-0" *ngIf="!isEventAdded(item.post_id)" (click)="addEvent(item)">{{
							buttonActivateText
						}}</a>
						<a
							class="btn btn-danger m-0"
							*ngIf="isEventAdded(item.post_id)"
							(click)="removeEvent(item.post_id)"
							>{{ buttonDeactivateText }}</a
						>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
</div>
