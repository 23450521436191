<div class="user-selector" *ngIf="result">
	<div class="user-selector-input-box">
		<input
			type="text"
			class="form-control"
			[(ngModel)]="searchInput"
			(input)="inputChanged()"
			(keydown)="inputKeyDown($event)"
			#userSelectorInput
		/>
		<a class="cursor-pointer" (click)="inputChanged()"><img src="assets/icons/search-green.svg" /></a>
	</div>
	<div class="user-selector-results">
		<span class="norestext" *ngIf="!is_loading && result.length == 0 && searchInput.length > 2"> No result. </span>
		<span class="norestext" *ngIf="!is_loading && result.length == 0 && searchInput.length <= 2">
			Start typing minimum 3 character to search user.
		</span>

		<div *ngIf="is_loading" class="text-center mt-2">
			<div class="loading-animation-container-sm">
				<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
			</div>
		</div>
		<ng-container *ngIf="!is_loading">
			<!-- selected users -->
			<ng-container *ngIf="searchInput === ''">
				<div class="result-row" *ngFor="let item of selectedUsers">
					<div class="result-row-image">
						<img [src]="item.data.user_avatar" />
					</div>
					<div class="result-row-name">
						<div class="result-row-name-content">{{ item.user_first_name }} {{ item.user_last_name }}</div>
					</div>
					<div class="result-row-btn" *ngIf="result">
						<a class="btn btn-primary m-0" *ngIf="!isUserAdded(item.user_id)" (click)="addUser(item)">{{
							buttonActivateText
						}}</a>
						<a
							class="btn btn-danger m-0"
							*ngIf="isUserAdded(item.user_id)"
							(click)="removeUser(item.user_id)"
							>{{ buttonDeactivateText }}</a
						>
					</div>
				</div>
			</ng-container>

			<!-- select user -->
			<div class="result-row" *ngFor="let item of result">
				<div class="result-row-image">
					<img [src]="item.data.user_avatar" />
				</div>
				<div class="result-row-name">
					<div class="result-row-name-content">{{ item.user_first_name }} {{ item.user_last_name }}</div>
				</div>
				<div class="result-row-btn" *ngIf="result">
					<a class="btn btn-primary m-0" *ngIf="!isUserAdded(item.user_id)" (click)="addUser(item)">{{
						buttonActivateText
					}}</a>
					<a
						class="btn btn-danger m-0"
						*ngIf="isUserAdded(item.user_id)"
						(click)="removeUser(item.user_id)"
						>{{ buttonDeactivateText }}</a
					>
				</div>
			</div>
		</ng-container>
	</div>
</div>
