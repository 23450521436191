<div>
	<gpe-post-general-frame
		*ngIf="post?.post_type != PostType.Event"
		[post]="post"
		[showCommentsClicked]="showCommentsClicked"
		[showShareClicked]="showShareClicked"
		[reportPost]="reportPost"
		[reportUser]="reportUser"
		[hidePost]="hidePost"
		[blockUser]="blockUser"
		[editPost]="editPost"
		[deletePost]="deletePost"
		[navigateToPost]="navigateToPost"
	></gpe-post-general-frame>
	<gpe-event-general-frame
		*ngIf="post?.post_type == PostType.Event"
		[post]="post"
		[showCommentsClicked]="showCommentsClicked"
		[showShareClicked]="showShareClicked"
		[reportPost]="reportPost"
		[reportUser]="reportUser"
		[hidePost]="hidePost"
		[blockUser]="blockUser"
		[editPost]="editPost"
		[deletePost]="deletePost"
		[navigateToPost]="navigateToPost"
	></gpe-event-general-frame>

	<!--<span class="btn btn-primary" (click)="showComments = !showComments">Comments</span>-->

	<ng-template #showCommentsModal let-modal>
		<a (click)="closeModal()" class="modal-close-button">X</a>
		<div class="m-4">
			<gpe-base-comment [firstReplyInput]="false" [post]="post"></gpe-base-comment>
		</div>
	</ng-template>

	<ng-template #showShareModal let-modal>
		<a (click)="closeModal()" class="modal-close-button">X</a>
		<div class="m-4">
			<gpe-post-share *ngIf="post && post.share_link" [url]="post.share_link"></gpe-post-share>
		</div>
	</ng-template>

	<ng-template #reportModal let-modal>
		<a (click)="closeModal()" class="modal-close-button">X</a>
		<div class="m-4" *ngIf="report">
			<div class="text-center mt-2" *ngIf="report.is_loading">
				<div class="loading-animation-container">
					<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
				</div>
			</div>
			<ng-container *ngIf="!report.is_loading">
				<div class="title">{{ report.title }}</div>
				<div class="mb-2">{{ report.text }}</div>
				<textarea
					class="form-control input-fields input-textarea mb-3"
					placeholder=""
					rows="3"
					[(ngModel)]="report.userText"
					*ngIf="report.showUserText"
				></textarea>

				<div class="row mt-4">
					<div class="col-md-10">
						<a class="btn btn-success post-button-add d-block" (click)="report.accepted()">{{
							report.btnAccept
						}}</a>
					</div>
					<div class="col-md-2 p-0">
						<a class="btn btn-secondary post-button-cancel d-block" (click)="report.rejected()">{{
							report.btnReject
						}}</a>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-template>
</div>
