import { HideService } from './../../../../core/services/hide.service';
import { Router } from '@angular/router';
import { BlockUserService } from './../../../../core/services/block-user.service';
import { ReportService } from './../../../../core/services/report.service';
import { AnimationOptions } from 'ngx-lottie';
import { PostService } from './../../../../core/services/post.service';
import { ReportModel } from './../../../../core/models/ReportModel';
import { UserService } from 'src/app/core/services/user.service';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { IPost, PostType } from '../../../../core/models/IPost';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { router } from 'ngx-bootstrap-icons';

@Component({
	selector: 'gpe-general-post',
	templateUrl: './general-post.component.html',
	styleUrls: ['./general-post.component.scss'],
})
export class GeneralPostComponent implements OnInit {
	PostType = PostType;
	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	@Input()
	post: IPost | undefined = undefined;

	constructor(
		private modalService: NgbModal,
		private userService: UserService,
		private postService: PostService,
		private reportService: ReportService,
		private blockUserService: BlockUserService,
		private hideService: HideService,
		private router: Router,
	) {}

	ngOnInit(): void {}

	@ViewChild('showCommentsModal', { read: TemplateRef }) showCommentsModal!: TemplateRef<any>;
	@ViewChild('showShareModal', { read: TemplateRef }) showShareModal!: TemplateRef<any>;
	@ViewChild('reportModal', { read: TemplateRef }) reportModal!: TemplateRef<any>;

	report: ReportModel | undefined;

	showCommentsClicked = () => {
		this.openModal(this.showCommentsModal);
	};
	showShareClicked = () => {
		this.openModal(this.showShareModal);
	};

	openModal(content: any) {
		this.modalService.open(content, {
			backdropClass: 'xlmodal-backdrop-style',
			size: 'xl',
			centered: true,
			scrollable: false,
			modalDialogClass: 'xlmodal-class',
		});
	}
	closeModal() {
		this.modalService.dismissAll();
	}

	reportPost = () => {
		if (this.post && this.post.post_user_id != this.userService.getLoginedUserId()) {
			this.report = {
				btnAccept: 'Send report',
				btnReject: 'Cancel',
				postId: this.post.post_id,
				userId: this.post.post_user_id,
				showUserText: true,
				text: 'Tell us what was your problem with this post.',
				title: 'Report Post',
				userText: '',
				is_loading: false,
				accepted: () => {
					if (this.report) {
						this.report.is_loading = true;
						this.reportService.reportPost(this.report.postId, this.report.userText).subscribe((val) => {
							this.closeModal();
						});
					}
				},
				rejected: () => {
					this.closeModal();
				},
			};

			this.openModal(this.reportModal);
		}
	};
	reportUser = () => {
		if (this.post && this.post.post_user_id != this.userService.getLoginedUserId()) {
			this.report = {
				btnAccept: 'Send report',
				btnReject: 'Cancel',
				postId: this.post.post_id,
				userId: this.post.post_user_id,
				showUserText: true,
				text: 'Tell us what was your problem with this user.',
				title: 'Report User',
				userText: '',
				is_loading: false,
				accepted: () => {
					if (this.report) {
						this.report.is_loading = true;
						this.reportService.reportUser(this.report.userId, this.report.userText).subscribe((val) => {
							this.closeModal();
						});
					}
				},
				rejected: () => {
					this.closeModal();
				},
			};

			this.openModal(this.reportModal);
		}
	};
	hidePost = () => {
		if (this.post && this.post.post_user_id != this.userService.getLoginedUserId()) {
			this.report = {
				btnAccept: 'Hide post',
				btnReject: 'Cancel',
				postId: this.post.post_id,
				userId: this.post.post_user_id,
				showUserText: false,
				text: 'Are you sure want to hide this post?',
				title: 'Hide post',
				userText: '',
				is_loading: false,
				accepted: () => {
					if (this.report) {
						this.report.is_loading = true;
						this.hideService.hidePost(this.report.postId).subscribe((val) => {
							this.post = undefined;
							this.closeModal();
						});
					}
				},
				rejected: () => {
					this.closeModal();
				},
			};

			this.openModal(this.reportModal);
		}
	};
	blockUser = () => {
		if (this.post && this.post.post_user_id != this.userService.getLoginedUserId()) {
			this.report = {
				btnAccept: 'Block user',
				btnReject: 'Cancel',
				postId: this.post.post_id,
				userId: this.post.post_user_id,
				showUserText: false,
				text: 'Are you sure want to block this user?',
				title: 'Block user',
				userText: '',
				is_loading: false,
				accepted: () => {
					if (this.report) {
						this.report.is_loading = true;
						this.blockUserService.blockUser(this.report.userId).subscribe((val) => {
							this.closeModal();
							this.router.navigate(['/']);
						});
					}
				},
				rejected: () => {
					this.closeModal();
				},
			};

			this.openModal(this.reportModal);
		}
	};
	editPost = () => {
		if (this.post && this.post.post_user_id == this.userService.getLoginedUserId()) {
			this.postService.openNewPostModal.emit(this.post.post_id);
		}
	};
	deletePost = () => {
		if (this.post && this.post.post_user_id == this.userService.getLoginedUserId()) {
			this.report = {
				btnAccept: 'Delete post',
				btnReject: 'Cancel',
				postId: this.post.post_id,
				userId: this.post.post_user_id,
				showUserText: false,
				text: 'Are you sure you want to delete your post? It is an irrevocable operation.',
				title: 'Delete Post',
				userText: '',
				is_loading: false,
				accepted: () => {
					if (this.report) {
						this.report.is_loading = true;
						this.postService.deletePost(this.report.postId).subscribe((val) => {
							if (val) {
								this.post = undefined;
								this.closeModal();
							}

							if (this.report) {
								this.report.is_loading = false;
							}
						});
					}
				},
				rejected: () => {
					this.closeModal();
				},
			};

			this.openModal(this.reportModal);
		}
	};

	navigateToPost = () => {
		if (this.post) {
			this.router.navigate(['/home/' + this.post.post_id]);
		}
	};
}
