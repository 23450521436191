<div class="row mt-5">
	<div class="col">
		<h2 translate>Edit cover</h2>
	</div>
</div>

<div *ngIf="is_loading" class="text-center mt-2">
	<div class="loading-animation-container">
		<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
	</div>
</div>
<ng-container *ngIf="!is_loading">
	<div class="row selector-mainer">
		<div [class.col-md-6]="vertical" class="p-5 pt-2">
			<div class="selector-title">Upload your cover</div>
			<div class="selector-box">
				<div class="selector-preview text-center">
					<img
						src="assets/icons/upload-box.jpg"
						[style.height]="vertical ? '70vh' : '40vh'"
						(click)="openMediaSelector()"
						*ngIf="selectedMedias.length == 0"
					/>
					<img
						[src]="selectedMedias[0].previewUrl"
						[style.height]="vertical ? '70vh' : '40vh'"
						*ngIf="selectedMedias.length > 0"
					/>
				</div>
				<div class="d-none">
					<gpe-media-selector
						[classList]="'d-none'"
						[uploadedFiles]="selectedMedias"
						[fileChanged]="selectedMediasChanged"
					></gpe-media-selector>
				</div>
			</div>

			<div class="profile-change-buttons text-center p-4">
				<div
					class="btn btn-primary"
					[class.btn-disabled]="selectedMedias.length == 0"
					(click)="saveCustomCover()"
				>
					Set this cover
				</div>
				<div class="btn btn-secondary" (click)="removeCustomCover()" *ngIf="selectedMedias.length > 0">
					Remove
				</div>
			</div>
		</div>
		<div [class.col-md-6]="vertical" class="p-5 pt-2">
			<div class="selector-title">Use GPE covers</div>
			<div class="selector-box">
				<div class="selector-preview text-center">
					<ng-container *ngFor="let cover of covers; let i = index">
						<img
							[src]="cover.covers_url"
							*ngIf="i == actualIndex"
							[style.height]="vertical ? '70vh' : '40vh'"
						/>
					</ng-container>
				</div>
				<div class="prev-btn" (click)="prev()"><img src="assets/icons/left-arrow.svg" /></div>
				<div class="next-btn" (click)="next()"><img src="assets/icons/right-arrow.svg" /></div>
			</div>

			<div class="profile-change-buttons text-center p-4">
				<div class="btn btn-primary" (click)="saveCover()">Set this cover</div>
			</div>
		</div>
	</div>
</ng-container>
