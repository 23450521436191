import { UserService } from 'src/app/core/services/user.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';

import { IPost } from '../../../../../core/models/IPost';
import { PostMediaType } from '../../../../../core/models/PostMediaType';

@Component({
	selector: 'gpe-post-type-media',
	templateUrl: './post-type-media.component.html',
	styleUrls: ['./post-type-media.component.scss'],
})
export class PostTypeMediaComponent implements OnInit {
	PostMediaType = PostMediaType;

	myUserId: number = 0;

	@Input() public likePost: ((postId: number, nextState: boolean) => void) | undefined;

	@Input()
	post: IPost | undefined = undefined;

	@Input()
	showCommentsClicked!: () => void;

	@Input()
	showShareClicked!: () => void;

	@Input() reportPost!: () => void;
	@Input() reportUser!: () => void;
	@Input() hidePost!: () => void;
	@Input() blockUser!: () => void;
	@Input() editPost!: () => void;
	@Input() deletePost!: () => void;
	@Input() navigateToPost!: () => void;

	@ViewChild(SwiperComponent) swiper!: SwiperComponent;

	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
	}
	swipePrev() {
		this.swiper.swiperRef.slidePrev();
	}
	swipeNext() {
		this.swiper.swiperRef.slideNext();
	}
}
