import { FileChangeEvent } from '@angular/compiler-cli/src/perform_watch';
import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { PostMediaType } from '../../../../../../core/models/PostMediaType';
import { FileHandle } from '../../../../../models/FileHandle';

@Component({
	selector: 'gpe-media-selector',
	templateUrl: './media-selector.component.html',
	styleUrls: ['./media-selector.component.scss'],
})
export class MediaSelectorComponent implements OnInit {
	@Input()
	uploadedFiles: FileHandle[] = [];

	@Input()
	fileChanged: (() => void) | undefined;

	@ViewChild('fileInput') fileInputVariable: ElementRef | undefined;
	@ViewChild('fileInputBtn') fileInputVariableBtn: ElementRef | undefined;

	public openSelector() {
		if (this.fileInputVariableBtn) {
			this.fileInputVariableBtn.nativeElement.click();
		}
	}

	constructor(private sanitizer: DomSanitizer) {}
	ngOnInit(): void {}
	async filesDropped(files: FileHandle[]) {
		for (const f of files) {
			if (!f.previewUrl) {
				let previewFile = f.file;
				if (f.file.type == 'video/mp4') {
					const videoCoverPromise = this.getVideoCover(f.file, 0.2);
					videoCoverPromise.then((value) => {
						if (value) {
							previewFile = new File([value], 'preview-' + Math.round(Math.random() * 10000) + '.jpeg');
						}
					});
					await videoCoverPromise;
				}
				this.getBase64(previewFile).then((value) => {
					f.thumbnailBase64 = value;
				});

				f.previewUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(previewFile));
			}
		}
		this.uploadedFiles.push(...files);
		if (this.fileChanged) {
			this.fileChanged();
		}
	}

	getBase64(file: File): Promise<string> {
		return new Promise<string>((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				resolve(reader.result as string);
			};
			reader.onerror = function (error) {
				console.log('Error: ', error);
				resolve('');
			};
		});
	}

	async onFileChange(event: Event) {
		const element = event.currentTarget as HTMLInputElement;
		const fileList: FileList | null = element.files;
		if (fileList) {
			for (let i = 0; i < fileList.length; i++) {
				const file = fileList.item(i);
				if (file) {
					let type = undefined;

					let previewFile = file;
					if (file.type.match('video.*')) {
						type = PostMediaType.Video;
						const videoCoverPromise = this.getVideoCover(file, 0.2);
						videoCoverPromise.then((value) => {
							if (value) {
								previewFile = new File(
									[value],
									'preview-' + Math.round(Math.random() * 10000) + '.jpeg',
								);
							}
						});
						await videoCoverPromise;
					}
					if (file.type.match('image.*')) {
						type = PostMediaType.Image;
					}
					if (file.type.match('audio.*')) {
						type = PostMediaType.Sound;
					}

					this.uploadedFiles.push({
						id: 0,
						file: file,
						url: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file)),
						previewUrl: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(previewFile)),
						type: type,
					});
				}
			}
		}
		if (this.fileChanged) {
			this.fileChanged();
		}
	}

	deleteFile(f: File) {
		this.uploadedFiles = this.uploadedFiles.filter(function (w) {
			return w.file.name != f.name;
		});
		if (this.fileChanged) {
			this.fileChanged();
		}
	}

	getVideoCover(file: File, seekTo = 0.0) {
		return new Promise<Blob | null>((resolve, reject) => {
			// load the file to a video player
			const videoPlayer = document.createElement('video');
			videoPlayer.setAttribute('src', URL.createObjectURL(file));
			videoPlayer.load();
			videoPlayer.addEventListener('error', (ex) => {
				reject('error when loading video file' + ex);
			});
			// load metadata of the video to get video duration and dimensions
			videoPlayer.addEventListener('loadedmetadata', () => {
				// seek to user defined timestamp (in seconds) if possible
				if (videoPlayer.duration < seekTo) {
					reject('video is too short.');
					return;
				}
				// delay seeking or else 'seeked' event won't fire on Safari
				setTimeout(() => {
					videoPlayer.currentTime = seekTo;
				}, 200);
				// extract video thumbnail once seeking is complete
				videoPlayer.addEventListener('seeked', () => {
					// define a canvas to have the same dimension as the video
					const canvas = document.createElement('canvas');
					canvas.width = videoPlayer.videoWidth;
					canvas.height = videoPlayer.videoHeight;
					// draw the video frame to canvas
					const ctx = canvas.getContext('2d');
					if (ctx) {
						ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
						// return the canvas image as a blob
						ctx.canvas.toBlob(
							(blob) => {
								resolve(blob);
							},
							'image/jpeg',
							0.75 /* quality */,
						);
					}
				});
			});
		});
	}
}
