<a (click)="closeModal()" class="modal-close-button">Close</a>
<div class="confirm-title">{{ title }}</div>
<div class="confirm-description">{{ description }}</div>
<div class="confirm-actions">
	<button
		class="btn confirm-btn"
		[ngClass]="{
			'btn-primary': confimType === ConfirmType.CONFIRM,
			'btn-danger': confimType === ConfirmType.DELETE
		}"
		(click)="confirm.emit(); closeModal()"
	>
		{{ confirmText }}
	</button>
	<button
		class="btn cancel-btn"
		[ngClass]="{
			'btn-warning': confimType === ConfirmType.CONFIRM,
			'btn-primary': confimType === ConfirmType.DELETE
		}"
		(click)="cancel.emit(); closeModal()"
	>
		{{ cancelText }}
	</button>
</div>
