<div
	class="image-wrapper"
	[ngStyle]="{ 'width.px': width + 4, 'height.px': width + 4, 'border-radius.px': width }"
	[ngClass]="{ highlighted: isHighlighted, 'is-blocked': isBlocked }"
>
	<img
		*ngIf="!isBlocked"
		class="profile-picture"
		[src]="pictureUrl ?? defaultProfileUrl"
		onerror="this.onerror=null; this.src='/assets/icons/user.svg';"
		[ngStyle]="{ 'width.px': width, 'height.px': width, 'border-radius.px': width / 2 }"
	/>
	<img *ngIf="isBlocked" src="assets/icons/blocked-user.svg" />
</div>

<div *ngIf="notificationCount > 0" class="notifications">{{ notificationCount < 10 ? notificationCount : '9+' }}</div>
