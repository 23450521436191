import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'gpe-company-table-search',
	templateUrl: './company-table-search.component.html',
	styleUrls: ['./company-table-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyTableSearchComponent {
	@Input() title = '';

	@Output() searchTerm = new EventEmitter<string>();

	public searchForm = new FormGroup({
		searchTerm: new FormControl(''),
	});

	constructor() {
		this.searchForm.valueChanges
			.pipe(debounceTime(500))
			.subscribe((change: { searchTerm: string }) => this.searchTerm.emit(change.searchTerm));
	}
}
