import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export enum ConfirmType {
	CONFIRM,
	DELETE,
}

@Component({
	selector: 'gpe-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent {
	@Input() title = 'Are you sure?';
	@Input() description = '';
	@Input() confirmText = 'Confirm';
	@Input() cancelText = 'Cancel';
	@Input() modalRef: any;
	@Input() confimType: ConfirmType = ConfirmType.CONFIRM;

	@Output() public confirm = new EventEmitter<void>();
	@Output() public cancel = new EventEmitter<void>();

	ConfirmType = ConfirmType;

	closeModal(): void {
		this.modalRef.close();
	}
}
