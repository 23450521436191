import { AnimationOptions } from 'ngx-lottie';
import { EventSelectorComponent } from './../common/event-selector/event-selector.component';
import { IPost } from './../../../../../core/models/IPost';
import { LocationService } from './../../../../../core/services/location.service';
import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { IEventCreate } from '../../../../../core/models/IEventCreate';
import { IPostCreate, PostType } from '../../../../../core/models/IPost';
import { IProfileMinimal } from '../../../../../core/models/IProfileMinimal';
import { MediaUsageType } from '../../../../../core/models/MediaUsageType';
import { PostMediaType } from '../../../../../core/models/PostMediaType';
import { PostService } from '../../../../../core/services/post.service';
import { PostMediaService } from '../../../../../core/services/post-media.service';
import { UserService } from '../../../../../core/services/user.service';
import { CreateMedia } from '../../../../models/CreateMedia';
import { FileHandle } from '../../../../models/FileHandle';
import { MediaSelectorComponent } from '../common/media-selector/media-selector.component';
import { UserSelectorComponent } from '../common/user-selector/user-selector.component';

@Component({
	selector: 'gpe-create-post-post',
	templateUrl: './post.component.html',
	styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit {
	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	@Input()
	postId!: number;

	@Input()
	post: IPost | undefined;

	submitted: boolean = false;
	errorColumns: string[] = [];

	selectedMedias: FileHandle[] = [];
	selectedMediaDropdownIsOpen = false;
	linkToEvent = false;
	deedbudsSelected: IProfileMinimal[] = [];
	eventSelected: IPost | undefined;

	locationObject: google.maps.places.PlaceResult | null = null;

	newPostObj: IPostCreate = {
		title: '',
		text: '',
		location_id: 0,
		marked_users: '',
		post_type: PostType.Media,
		deedbuds: '',
		city_id: 0,
		has_media: false,
		linked_post_id: 0,
		bgcolor: '',
	};

	@Input()
	bgColors!: string[];

	@Input()
	is_loading!: boolean;

	@Input()
	closeModal!: () => void;

	@Input()
	uploadAttachments!: (postId: number, postMedia: CreateMedia[]) => Promise<boolean>;

	@ViewChildren(UserSelectorComponent) userSelectors: QueryList<UserSelectorComponent> | undefined;
	@ViewChild(MediaSelectorComponent) mediaSelector: MediaSelectorComponent | undefined;
	@ViewChild('mediaSelectorDropdown') mediaSelectorDropdown: ElementRef | undefined;
	@ViewChildren(EventSelectorComponent) eventSelectors: QueryList<EventSelectorComponent> | undefined;

	constructor(
		private userService: UserService,
		private postService: PostService,
		private postMediaService: PostMediaService,
		private router: Router,
		private locationService: LocationService,
	) {}

	async ngOnInit(): Promise<void> {
		if (this.postId > 0 && this.post) {
			this.newPostObj = {
				bgcolor: this.post.post_content_bgcolor,
				title: this.post.post_content_title,
				text: this.post.post_content_text,
				linked_post_id: this.post.post_link_post_id,
				location_id: this.post.location_id,
				marked_users: '',
				post_type: PostType.Text,
				deedbuds: '',
				city_id: 0,
				has_media: false,
			};

			this.linkToEvent = this.post.post_link_post_id > 0;

			if (this.post.medias && this.post.medias.length > 0) {
				let first = true;
				for (let media of this.post.medias) {
					let f: File | undefined;
					let fUrl = '';
					if (media.type == PostMediaType.Image) {
						fUrl = media.picture.file;
						f = await this.createFile(fUrl, 'image/jpeg', 'image.jpg');
					} else if (media.type == PostMediaType.Video) {
						if (media.video.thumbnail) {
							fUrl = media.video.thumbnail;
							f = await this.createFile(fUrl, 'image/jpeg', 'image.jpg');
						}
					}

					if (f != undefined) {
						let item: FileHandle = {
							file: f,
							id: media.media_id,
							url: fUrl,
							previewUrl: fUrl,
							primary: first,
						};
						this.selectedMedias.push(item);
						first = false;
					}
				}
			}

			for (let u of this.post.marked_users) {
				this.deedbudsSelected.push(this.userService.convertIUserMinimalToIProfileMinimal(u));
			}
			if (this.post.marked_users_invited) {
				for (let u of this.post.marked_users_invited) {
					this.deedbudsSelected.push(this.userService.convertIUserMinimalToIProfileMinimal(u));
				}
			}

			this.postService.getPostById(this.post.post_link_post_id).subscribe(
				(val) => {
					this.eventSelected = val;
				},
				(err) => {
					this.linkToEvent = false;
				},
			);
		}
	}

	async createFile(url: string, metaType: string, name: string) {
		let response = await fetch(url);
		let data = await response.blob();
		let metadata = {
			type: metaType,
		};
		let file = new File([data], name, metadata);
		return file;
	}

	eventSearch = (searchStr: string, page: number, limit = 15): Observable<IPost[]> => {
		return this.postService.getEventsBySearch(searchStr, page, limit);
	};
	eventChanged = (selected: IPost | undefined): void => {
		this.eventSelected = selected;
	};

	deedbudsSearch = (searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> => {
		return this.userService.searchPostCreateDeedbuds(searchStr, page, limit);
	};
	deedbudsChanged = (selected: IProfileMinimal[]): void => {
		this.deedbudsSelected = selected;
	};

	toggled(event: boolean, refNumber: number) {
		if (this.userSelectors) {
			for (const e of this.userSelectors) {
				if (e.refNumber && e.refNumber == refNumber) {
					if (!event) {
						e.clearInput();
					} else {
						e.focusInput();
					}
				}
			}
		}
	}

	removeMedia(file: FileHandle) {
		this.selectedMedias = this.selectedMedias.filter((value) => value.url != file.url);
	}
	setToMain(file: FileHandle) {
		for (let m of this.selectedMedias) {
			m.primary = false;
		}
		file.primary = true;
	}
	selectedMediasChanged = () => {
		this.selectedMediaDropdownIsOpen = false;
	};

	toggledEventDropdown(event: boolean, refNumber: number) {
		if (this.eventSelectors) {
			for (const e of this.eventSelectors) {
				if (e.refNumber && e.refNumber == refNumber) {
					if (!event) {
						e.clearInput();
					} else {
						e.focusInput();
					}
				}
			}
		}
	}

	hasErrorColumn(column: string): boolean {
		return this.errorColumns.includes(column);
	}
	checkRequiredColumns() {
		this.errorColumns = [];
		if (this.newPostObj.title.length < 3) {
			this.errorColumns.push('title');
		}
		if (this.newPostObj.text.length < 3) {
			this.errorColumns.push('text');
		}
	}

	async addPost() {
		this.is_loading = true;

		this.submitted = true;
		this.checkRequiredColumns();
		if (this.errorColumns.length > 0) {
			this.is_loading = false;
			return;
		}

		if (this.locationObject != null) {
			let location = await this.locationService.getLocationId(this.locationObject);
			this.newPostObj.location_id = location.location_id;
		}

		if (this.selectedMedias.length > 0) {
			this.newPostObj.has_media = true;
		}

		if (this.eventSelected) {
			this.newPostObj.linked_post_id = this.eventSelected.post_id;
		} else {
			this.newPostObj.linked_post_id = 0;
		}
		if (this.deedbudsSelected) {
			let marked = '';
			for (const i of this.deedbudsSelected) {
				marked += i.user_id + ',';
			}
			this.newPostObj.deedbuds = marked;
		} else {
			this.newPostObj.deedbuds = '';
		}

		if (this.postId > 0) {
			this.postService.updatePost(this.postId, this.newPostObj).subscribe(
				(value) => {
					if (value && value.post_id > 0) {
						this._uploadAttachment(value.post_id);
					}
				},
				(error) => {
					this.is_loading = false;
				},
				() => {},
			);
		} else {
			this.postService.createPost(this.newPostObj).subscribe(
				(value) => {
					if (value && value.post_id > 0) {
						this._uploadAttachment(value.post_id);
					}
				},
				(error) => {
					this.is_loading = false;
				},
				() => {},
			);
		}
	}

	private _uploadAttachment(post_id: number) {
		const medias: CreateMedia[] = [];
		for (const media of this.selectedMedias) {
			medias.push({
				file: media,
				usage_type: MediaUsageType.Post,
			});
		}
		this.uploadAttachments(post_id, medias).then((value1) => {
			this.closeModal();
			this.is_loading = false;
			this.router.navigate(['/profile'], { queryParams: { postId: post_id, updated: new Date().getTime() } });
		});
	}

	locationChanged = (newLocationObject: google.maps.places.PlaceResult | null) => {
		this.locationObject = newLocationObject;
	};

	openMediaSelector() {
		if (this.mediaSelector) {
			this.mediaSelector.openSelector();
		}
	}
}
