import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { EventJoinedMembers } from 'src/app/core/models/IEvent';

@Component({
	selector: 'gpe-event-joined-members-modal',
	templateUrl: './event-joined-members-modal.component.html',
	styleUrls: ['./event-joined-members-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventJoinedMembersModalComponent {
	@Input() joinedMembers: EventJoinedMembers[] | undefined | null;
	@Input() title: string | undefined;

	loadingAnimationOptions: AnimationOptions = {
		path: '/assets/lottie/100931-loading.json',
	};
}
