<div class="title">
	{{ title }}
</div>
<div class="scrollable-container">
	<div class="members-list" *ngFor="let member of joinedMembers">
		<div class="d-flex align-items-center">
			<gpe-profile-picture [pictureUrl]="member.avatar"></gpe-profile-picture>
			<div class="name">
				{{ member.name }}
			</div>
		</div>

		<div class="actions">
			<button class="btn btn-secondary">Profil</button>
		</div>
	</div>

	<div *ngIf="!joinedMembers" class="text-center mt-2">
		<div class="loading-animation-container">
			<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
		</div>
	</div>
</div>
