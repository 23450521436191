import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'gpe-company-table-email',
	templateUrl: './company-table-email.component.html',
	styleUrls: ['./company-table-email.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyTableEmailComponent {
	@Input() public title = '';

	@Output() private email = new EventEmitter<string>();

	public emailForm = new FormGroup({
		email: new FormControl('', Validators.email),
	});

	public sendEmail(): void {
		if (this.emailForm.valid) {
			this.email.emit(this.emailForm.value.email);
			this.emailForm.controls.email.setValue('');
		}
	}
}
