import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from './core/services/utility.service';

@Component({
	selector: 'gpe-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'angular-base-component-login';
	canUseFromThisLocation: boolean = true;

	constructor(private translate: TranslateService, private utilityService: UtilityService) {
		translate.setDefaultLang('en');
		translate.use(translate.getBrowserLang());
	}

	ngOnInit() {
		this.utilityService.getCanUse().subscribe((val) => {
			this.canUseFromThisLocation = val;
		});
	}

	useLanguage(language: string): void {
		this.translate.use(language);
	}
}
