<gpe-header></gpe-header>
<div *ngIf="is_loading" class="text-center mt-2">
	<div class="loading-animation-container">
		<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
	</div>
</div>

<div *ngIf="!is_loading">
	<div class="container mb-5">
		<div class="row">
			<div class="col pt-5" [class.col-md-8]="this.myUserId > 0">
				<h1 class="title">Suggest idea</h1>
				<div class="text mt-3">
					Do you have an idea how we could improve the Good People app? Suggest an idea to us, and we will
					consider making it real for you. :)
					<br />
					<input type="text" class="form-control mt-5" placeholder="Subject" [(ngModel)]="subject" />
					<textarea
						class="form-control mt-2"
						placeholder="Description"
						rows="6"
						[(ngModel)]="description"
					></textarea>

					<span class="btn post-button-add cursor-pointer mt-2" (click)="send()">Send</span>
				</div>
			</div>
			<div class="col-md-4" *ngIf="this.myUserId > 0">
				<gpe-sidebar-default></gpe-sidebar-default>
			</div>
		</div>
	</div>
</div>
