import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/api/api.service';

import { ApiResponse } from './../../shared/models/ApiResponse';
import { IBlockUsers, IBlockUsersData } from './../models/IBlockedUsers';
import { IProfileBatchGetInner } from './../models/IProfile';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class BlockUserService {
	public blockChangedEmitter = new EventEmitter<void>();

	constructor(private userService: UserService, private apiService: ApiService) {}

	blockUser(userId: number): Observable<string[]> {
		const url = 'user/blocked';

		const data = new FormData();
		data.append('user_id', userId.toString());

		return this.apiService.post<ApiResponse<IBlockUsersData>>(url, data).pipe(
			map((res) => {
				this.blockChangedEmitter.emit();
				return res.data.blocked_users;
			}),
		);
	}
	unblockUser(userId: number): Observable<string[]> {
		const url = 'user/blocked/' + userId.toString();

		return this.apiService.delete<ApiResponse<IBlockUsersData>>(url).pipe(
			map((res) => {
				this.blockChangedEmitter.emit();
				return res.data.blocked_users;
			}),
		);
	}

	getBlockedUserIds(): Observable<number[]> {
		return this.apiService
			.get<IBlockUsers>('user/blocked')
			.pipe(map((val) => val.data.blocked_users.map((id) => +id)));
	}

	getBlockedUserList(): Observable<IProfileBatchGetInner[]> {
		return new Observable((observer) => {
			this.apiService.get<IBlockUsers>('user/blocked').subscribe((res) => {
				const ids: number[] = [];
				if (res && res.data && res.data.blocked_users) {
					for (const uId of res.data.blocked_users) {
						ids.push(parseInt(uId));
					}
					if (ids.length > 0) {
						this.userService.getUserBatch(ids).subscribe((val) => {
							observer.next(val);
							observer.complete();
						});
					} else {
						observer.next([]);
						observer.complete();
					}
				} else {
					observer.next([]);
					observer.complete();
				}
			});
		});
	}
}
