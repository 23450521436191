import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { SexEnum } from '../models/user';

@Pipe({
	name: 'genderPipe',
})
export class genderPipe implements PipeTransform {
	transform(value: string) {
		switch (Number.parseInt(value)) {
			case SexEnum.man:
				return 'Man';
			case SexEnum.woman:
				return 'Woman';
			case SexEnum.other:
				return 'Other';
		}
		return 'undefined';
	}
}
