import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportProblemComponent } from './core/common/report-problem/report-problem.component';
import { SuggestIdeaComponent } from './core/common/suggest-idea/suggest-idea.component';
import { AuthGuard } from './core/guards/auth.guard';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { NotfoundComponent } from './core/notfound/components/notfound.component';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{
		path: '',
		component: MainLayoutComponent,
		children: [
			{
				path: 'home',
				loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
				canActivate: [AuthGuard],
			},
			{
				path: 'posts',
				loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
				canActivate: [AuthGuard],
			},

			{
				path: 'calendar',
				loadChildren: () => import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
				canActivate: [AuthGuard],
			},

			{
				path: 'chat',
				loadChildren: () => import('./modules/chat/chat.module').then((m) => m.ChatModule),
				canActivate: [AuthGuard],
			},

			{
				path: 'company',
				loadChildren: () => import('./modules/company/company.module').then((m) => m.CompanyModule),
				canActivate: [AuthGuard],
			},

			{
				path: 'pages',
				loadChildren: () => import('./modules/pages/pages.module').then((m) => m.PagesModule),
				/*canActivate: [AuthGuard],*/
			},
			{
				path: 'profile',
				loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
				canActivate: [AuthGuard],
			},

			{
				path: 'rooms',
				loadChildren: () => import('./modules/rooms/rooms.module').then((m) => m.RoomsModule),
				canActivate: [AuthGuard],
			},

			{
				path: 'users',
				loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
				canActivate: [AuthGuard],
			},
		],
	},

	{
		path: 'auth',
		loadChildren: () =>
			import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
	},

	{ path: 'report-problem', component: ReportProblemComponent, canActivate: [AuthGuard] },
	{ path: 'suggest-idea', component: SuggestIdeaComponent, canActivate: [AuthGuard] },

	{ path: 'login', redirectTo: '/auth/login' },
	{ path: 'register', redirectTo: '/auth/register' },

	{ path: '**', component: NotfoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
