import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { IEventCreate } from '../models/IEventCreate';
import { IPost, IPostCreate, IPostGet, IPostGetList } from '../models/IPost';
import { IPostLikeBatch, IPostLikeBatchGet } from '../models/IPostLikeBatch';

import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class PostService {
	private apiUrl = environment.apiURL;
	constructor(private authService: AuthService, private http: HttpClient, private userService: UserService) {}

	openNewPostModal = new EventEmitter<number>();

	getPostsMyPosts(page: number, limit = 15): Observable<IPost[]> {
		return this.getPostsByUser(this.userService.getLoginedUserId(), page, limit);
	}

	getPostsMyPostsWithLike(page: number, limit = 15): Observable<IPost[]> {
		return this.getPostsByUserWithLikes(this.userService.getLoginedUserId(), page, limit);
	}

	getEventsBySearchAndCity(searchStr: string, cityId: number, page: number, limit = 15): Observable<IPost[]> {
		const searchEncoded = encodeURIComponent(searchStr);

		const url = `${this.apiUrl}/post/event_v2?page=${page}&limit=${limit}&keyword=${searchEncoded}&city_id=${cityId}`;

		return this.http.get<ApiResponse<IPostGetList>>(url).pipe(
			map((res) => {
				return res.data.posts;
			}),
		);
	}

	getEventsBySearch(searchStr: string, page: number, limit = 15): Observable<IPost[]> {
		const searchEncoded = encodeURIComponent(searchStr);

		const url = `${this.apiUrl}/post/event_v2?page=${page}&limit=${limit}&keyword=${searchEncoded}`;

		return this.http.get<ApiResponse<IPostGetList>>(url).pipe(
			map((res) => {
				return res.data.posts;
			}),
		);
	}
	getPostsBySearch(searchStr: string, page: number, limit = 15, type = 0, city_id = 0): Observable<IPost[]> {
		const searchEncoded = encodeURIComponent(searchStr);

		const url = `${this.apiUrl}/post?page=${page}&limit=${limit}&keyword=${searchEncoded}&post_type=${type}&city_id=${city_id}`;

		return this.http.get<ApiResponse<IPostGetList>>(url).pipe(
			map((res) => {
				return res.data.posts;
			}),
		);
	}

	getMyPostById(postId: number): Observable<IPost> {
		const url = `${this.apiUrl}/post/${postId}`;

		return this.http.get<ApiResponse<IPostGet>>(url).pipe(
			map((res) => {
				return res.data.post;
			}),
		);
	}

	getPostById(postId: number): Observable<IPost> {
		const url = `${this.apiUrl}/post/${postId}`;

		return this.http.get<ApiResponse<IPostGet>>(url).pipe(
			map((res) => {
				return res.data.post;
			}),
		);
	}

	getPostsByUser(userId: number, page: number, limit = 15): Observable<IPost[]> {
		if (userId == 0) {
			userId = this.userService.getLoginedUserId();
		}
		const url = `${this.apiUrl}/post/user/${userId}?page=${page}&limit=${limit}`;

		return this.http.get<ApiResponse<IPostGetList>>(url).pipe(
			map((res) => {
				return res.data.posts;
			}),
		);
	}

	deletePost(postId: number): Observable<boolean> {
		const url = `${this.apiUrl}/post/${postId}`;

		return this.http.delete<ApiResponse<Object>>(url).pipe(
			map((res) => {
				return res.success == 1;
			}),
		);
	}
	getPostsLikes(postIds: number[]): Observable<IPostLikeBatch[]> {
		const ids = postIds.join(',');
		const url = `${this.apiUrl}/post/userlike/batch_post?post_ids=${ids}`;

		return this.http.get<ApiResponse<IPostLikeBatchGet>>(url).pipe(
			map((res) => {
				return res.data.result;
			}),
		);
	}

	likePost(postId: number, nextState: boolean): Observable<boolean> {
		const url = `${this.apiUrl}/post/like/${postId}`;

		if (nextState) {
			return this.http.post<ApiResponse<any>>(url, {}).pipe(
				map((res) => {
					return true;
				}),
			);
		} else {
			return this.http.delete<ApiResponse<any>>(url, {}).pipe(
				map((res) => {
					return true;
				}),
			);
		}
	}

	getPostsBySearchWithLikes(searchStr: string, page: number, limit = 15, type = 0, city_id = 0): Observable<IPost[]> {
		return new Observable((observer) => {
			let posts: IPost[] = [];
			const getPosts = new Promise((resolve) => {
				this.getPostsBySearch(searchStr, page, limit, type, city_id).subscribe(
					(value) => {
						posts = value;
						const ids = this._getIdArrFromPosts(posts);
						this.getPostsLikes(ids).subscribe(
							(value1) => {
								this._setLikesForPosts(posts, value1);
							},
							(error) => {},
							() => {
								resolve(true);
							},
						);
					},
					(error) => {},
					() => {},
				);
			});

			Promise.all([getPosts]).then((r) => {
				observer.next(posts);
				observer.complete();
			});
		});
	}
	getPostsByUserWithLikes(userId: number, page: number, limit = 15): Observable<IPost[]> {
		return new Observable((observer) => {
			let posts: IPost[] = [];
			const getPosts = new Promise((resolve) => {
				this.getPostsByUser(userId, page, limit).subscribe(
					(value) => {
						posts = value;
						const ids = this._getIdArrFromPosts(posts);
						this.getPostsLikes(ids).subscribe(
							(value1) => {
								this._setLikesForPosts(posts, value1);
							},
							(error) => {},
							() => {
								resolve(true);
							},
						);
					},
					(error) => {},
					() => {},
				);
			});

			Promise.all([getPosts]).then((r) => {
				observer.next(posts);
				observer.complete();
			});
		});
	}
	updatePost(postId: number, post: IPostCreate): Observable<IPost> {
		const url = `${this.apiUrl}/post/${postId}`;

		return this.http.put<ApiResponse<IPostGet>>(url, this._createPostData(post)).pipe(
			map((res) => {
				return res.data.post;
			}),
		);
	}
	createPost(post: IPostCreate): Observable<IPost> {
		const url = `${this.apiUrl}/post`;

		return this.http.post<ApiResponse<IPostGet>>(url, this._createPostData(post)).pipe(
			map((res) => {
				return res.data.post;
			}),
		);
	}
	private _createPostData(post: IPostCreate) {
		const data = new FormData();
		data.append('post_text', post.text);
		if (post.linked_post_id > 0) {
			data.append('link_post_id', post.linked_post_id.toString());
		}
		data.append('location_id', post.location_id.toString());
		data.append('post_title', post.title);

		if (post.marked_users) {
			data.append('marked_users', post.marked_users);
		}

		if (post.city_id) {
			data.append('city_id', post.city_id.toString());
		}

		if (post.deedbuds) {
			data.append('deedbuds', post.deedbuds);
		}

		if (post.bgcolor) {
			data.append('bgcolor', post.bgcolor);
		}
		if (post.has_media) {
			data.append('has_media', 'true');
		}

		if (post.post_type) {
			data.append('post_type', post.post_type.toString());
		}
		return data;
	}
	updateEvent(postId: number, post: IEventCreate): Observable<IPost> {
		const url = `${this.apiUrl}/post/event_v3/${postId}`;

		return this.http.put<ApiResponse<IPostGet>>(url, this._getEventDataObj(post)).pipe(
			map((res) => {
				return res.data.post;
			}),
		);
	}
	createEvent(post: IEventCreate): Observable<IPost> {
		const url = `${this.apiUrl}/post/event_v3`;

		return this.http.post<ApiResponse<IPostGet>>(url, this._getEventDataObj(post)).pipe(
			map((res) => {
				return res.data.post;
			}),
		);
	}

	private _getEventDataObj(post: IEventCreate) {
		const data = new FormData();
		data.append('event_text', post.text);
		data.append('location_id', post.location_id.toString());
		data.append('event_title', post.title);
		data.append('event_subtitle', '0');

		if (post.event_date_from) {
			data.append('event_date_from_ms', post.event_date_from.getTime().toString());
		}
		if (post.event_date_to) {
			data.append('event_date_to_ms', post.event_date_to.getTime().toString());
		}
		if (post.marked_users) {
			data.append('marked_users', post.marked_users);
		}

		if (post.deedbuds) {
			data.append('deedbuds', post.deedbuds);
		}
		data.append('event_online', post.event_online ? '1' : '0');

		data.append('event_max_people', post.event_max_people ? post.event_max_people.toString() : '0');
		data.append('event_private', post.event_private ? '1' : '0');

		if (post.has_media) {
			data.append('has_media', 'true');
		}

		if (post.post_type) {
			data.append('post_type', post.post_type.toString());
		}

		return data;
	}

	private _getIdArrFromPosts(posts: IPost[]): number[] {
		const ids: number[] = [];
		for (const post of posts) {
			ids.push(post.post_id);
		}

		return ids;
	}
	private _setLikesForPosts(posts: IPost[], likes: IPostLikeBatch[]) {
		for (const post of posts) {
			for (const like of likes) {
				if (post.post_id == like.id) {
					post.post_liked = like.liked;
					post.post_like_count = like.like_count;
				}
			}
		}
	}
}
