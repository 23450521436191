import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReportModel } from './../../../../core/models/ReportModel';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IComment } from '../../../../core/models/IComment';
import { IProfileMinimal } from '../../../../core/models/IProfileMinimal';
import { PostCommentsService } from '../../../../core/services/post-comments.service';
import { UserService } from '../../../../core/services/user.service';
import { ComplexTextInputComponent } from '../../complex-text-input/complex-text-input.component';
import { IProfile } from '../../../../core/models/IProfile';

@Component({
	selector: 'gpe-comment-item',
	templateUrl: './comment-item.component.html',
	styleUrls: ['./comment-item.component.scss'],
})
export class CommentItemComponent implements OnInit {
	myUserId = 0;
	myUser: IProfile | null = null;
	is_editing = false;
	showReply = false;
	showReplyList = false;
	showCommentNum = 3;

	@ViewChildren(ComplexTextInputComponent) editingText: QueryList<ComplexTextInputComponent> | undefined;

	@Input()
	comment: IComment | undefined;

	@Input()
	mainComment: boolean | undefined;

	@Input()
	userFollowing: ((userId: number, nextState: boolean, comments?: IComment[] | undefined) => void) | undefined;

	showComment: IComment | undefined;
	contextObj: CommentItemContextObj = {
		userData: [],
		userFollowing: undefined,
	};

	constructor(
		private userService: UserService,
		private postCommentService: PostCommentsService,
		private router: Router,
		private _ActivatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.comment.firstChange) {
			if (changes.comment.previousValue.post_comment_id !== changes.comment.currentValue.post_comment_id) {
				this.processInputComment();
			}
		}
	}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
		this.userService.getUser(0).subscribe((value) => {
			this.myUser = value;
		});

		this.contextObj.userFollowing = this.userFollowing;

		this.processInputComment();
	}

	processInputComment() {
		if (this.comment) {
			if (this.comment.marked_users) {
				this.contextObj.userData.push(...this.comment.marked_users);
			}

			this.showComment = { ...this.comment };
			const re = /({@USER-\d+})/g;
			let m;

			let s = this.showComment.post_comment_text;

			do {
				m = re.exec(s);
				if (m) {
					const temp = m[0].split('-')[1];
					const userId = Number.parseInt(temp.substring(0, temp.length - 1));

					const UserHrefSection =
						'<gpe-user-inline [userId]="' +
						userId +
						'" [userData]="context.userData" [userFollowing]="context.userFollowing" ></gpe-user-inline>';

					s = this.replaceRange(s, m.index, m.index + m[0].length, UserHrefSection);
				}
			} while (m);

			this.showComment.post_comment_text = s;
		}
	}

	replaceRange(s: string, start: number, end: number, substitute: string) {
		return s.substring(0, start) + substitute + s.substring(end);
	}

	likeComment(nextState: boolean) {
		if (this.showComment) {
			this.showComment.post_comment_liked = nextState;

			this.postCommentService.likeComment(this.showComment.post_comment_id, nextState).subscribe(
				(value) => {
					if (this.showComment) {
						this.showComment.post_comment_like_count = value.post_comment_like_count;
					}
					this.cdr.markForCheck();
					//update sublist
					//  this.comment = value
					//  this.processInputComment()
				},
				(error) => {
					if (this.showComment) {
						this.showComment.post_comment_liked = nextState;
					}
				},
			);
		}
	}

	navigateToUser(userId: number) {
		this.router.navigate(['/users/' + userId]);
	}

	editComment(edit: boolean) {
		this.is_editing = edit;
	}
	saveComment() {
		if (this.editingText) {
			for (const e of this.editingText) {
				if (e.contentId > 0 && this.showComment) {
					this.postCommentService
						.editComment(
							this.showComment.post_comment_post_id,
							this.showComment.post_comment_id,
							e.getClearStr(),
						)
						.subscribe(
							(value) => {
								if (this.comment) {
									this.comment = value;
									this.processInputComment();
									e.loadDefaultString('', []);
								}
								this.is_editing = false;
								this.cdr.markForCheck();
							},
							(error) => {},
							() => {},
						);
				}
			}
		}
	}

	addNewComment(parentId: number) {
		if (this.editingText) {
			for (const e of this.editingText) {
				if (e.contentId == 0 && this.showComment) {
					this.postCommentService
						.addComment(
							this.showComment.post_comment_post_id,
							this.showComment.post_comment_id,
							e.getClearStr(),
						)
						.subscribe(
							(value) => {
								if (this.comment && this.comment.childs) {
									if (!this.comment.childs) {
										this.comment.childs = [];
									}

									this.comment.childs.push(value);
									e.loadDefaultString('', []);
								}
							},
							(error) => {},
							() => {},
						);
				}
			}
		}
	}

	@ViewChild('reportModal', { read: TemplateRef }) reportModal!: TemplateRef<any>;

	report: ReportModel | undefined;
	reportModalHref: NgbModalRef | undefined;
	openModal(content: any) {
		this.reportModalHref = this.modalService.open(content, {
			backdropClass: 'nono-backdrop-style',
			size: 'xl',
			centered: true,
			scrollable: false,
			modalDialogClass: 'lgmodal-class',
		});
	}
	closeModal() {
		this.reportModalHref?.close();
	}
	deleteComment() {
		if (this.showComment) {
			this.report = {
				btnAccept: 'Delete comment',
				btnReject: 'Cancel',
				postId: this.showComment.post_comment_post_id,
				userId: this.showComment.post_comment_user_id,
				showUserText: false,
				text: 'Are you sure you want to delete your comment?',
				title: 'Delete comment',
				userText: '',
				is_loading: false,
				accepted: () => {
					if (this.report && this.showComment) {
						this.postCommentService
							.deleteComment(this.showComment.post_comment_post_id, this.showComment.post_comment_id)
							.subscribe((value) => {
								if (value) {
									this.comment = undefined;
									this.showComment = undefined;
									this.closeModal();
								} else {
									//TODO: !!
									alert('hiba');
								}
							});
					}
					this.cdr.detectChanges();
				},
				rejected: () => {
					this.closeModal();
				},
			};
			this.openModal(this.reportModal);
		}
	}

	@Output()
	@ViewChild('textInputComponent')
	public textInputComponentRef: ElementRef | undefined;

	showReplyChg() {
		this.showReply = true;
	}
}

interface CommentItemContextObj {
	userData: IProfileMinimal[];
	userFollowing: ((userId: number, nextState: boolean, comments?: IComment[] | undefined) => void) | undefined;
}
