<ng-container *ngIf="canUseFromThisLocation">
	<div class="desktop">
		<router-outlet></router-outlet>
	</div>
	<div class="mobile">
		<div class="text-center">
			<img class="site-logo mt-2 mb-3" src="assets/images/gpe-logo-colored.png" />
		</div>
		<div class="container pb-4">
			<img src="https://goodpeople.world/assets/slider_img.svg" width="100%" />
			<div class="slider_title">Spreading the good<br />changes the world.</div>
			<div class="slider_text">
				There is a need to provide a platform that amplifies inspiring unsung voices, positively impacts users
				and the world as a whole.
			</div>
			<span>This page is currently only available from a desktop computer, download mobile app to use.</span
			><br />
			<a href="https://goodpeople.world/en/download" class="btn nav-green page-button mt-2">Download the app</a>
			<br /><br /><br /><br />
			<a href="https://goodpeople.world/" class="btn btn-sm page-button mt-4">Go to our website</a>
			<br /><br /><br /><br />
		</div>
	</div>
</ng-container>
<ng-container *ngIf="!canUseFromThisLocation">
	<div class="text-center">
		<img class="site-logo mt-2 mb-3" src="assets/images/gpe-logo-colored.png" />
	</div>
	<div class="container pb-4 text-center">
		<img src="https://goodpeople.world/assets/slider_img.svg" width="100%" style="max-width: 400px" />
		<div class="slider_title m-auto mt-3">Our platform is only avaiable from Hungary.</div>
		<div class="slider_text m-auto mt-1">Try again later.</div>
		<a href="https://goodpeople.world/en/download" class="btn nav-green page-button mt-3">Download the app</a>
		<br /><br /><br /><br />
		<a href="https://goodpeople.world/" class="btn btn-sm page-button mt-4">Go to our website</a>
		<br /><br /><br /><br />
	</div>
</ng-container>
