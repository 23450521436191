<tagify
	[ngModel]="showComment"
	class="form-control border-0"
	[settings]="settings"
	[whitelist]="whitelist$"
	[readonly]="readonly"
	[name]="tagifyName"
	(input)="onInput($event)"
	(add)="onAdd($event)"
	(remove)="onRemove($event)"
	autofocus
></tagify>
