<span *ngIf="actualUserData">
	<ng-template #popContent>
		<div (mouseover)="openTooltip(t1)" (mouseleave)="closeTooltip(t1)">
			<div class="row" style="align-items: center">
				<div class="col-3">
					<span
						(click)="navigateToUser(actualUserData.user_id)"
						class="avatar-image cursor-pointer"
						[style.background-image]="'url(' + actualUserData.user_avatar + ')'"
					></span>
				</div>
				<div class="col-9">
					{{ actualUserData.user_first_name }} {{ actualUserData.user_last_name }}
					<ng-container *ngIf="userFollowing">
						<a
							class="btn btn-sm btn-success"
							*ngIf="!actualUserData.is_followed"
							(click)="userFollowing(actualUserData.user_id, true)"
							>Follow</a
						>
						<a
							class="btn btn-sm btn-secondary"
							*ngIf="actualUserData.is_followed"
							(click)="userFollowing(actualUserData.user_id, false)"
							>Unfollow</a
						>
					</ng-container>
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-6">
					<a class="btn btn-block btn-secondary" (click)="navigateToUser(actualUserData.user_id)">Profile</a>
				</div>
				<div class="col-6">
					<a class="btn btn-block btn-secondary" (click)="navigateToUser(actualUserData.user_id)">Message</a>
				</div>
			</div>
		</div>
	</ng-template>

	<a
		style="color: blue"
		[ngbPopover]="popContent"
		(mouseover)="openTooltip(t1)"
		(mouseleave)="closeTooltip(t1)"
		#t1="ngbPopover"
	>
		{{ actualUserData.user_first_name }} {{ actualUserData.user_last_name }}
	</a>
</span>
<span *ngIf="!actualUserData"> @ </span>
