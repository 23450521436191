<div class="title">Add voice</div>

<div *ngIf="is_loading" class="text-center mt-2">
	<div class="loading-animation-container">
		<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
	</div>
</div>

<div [class.disableall]="is_loading">
	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-6">
					<input
						type="text"
						class="form-control input-fields input-text"
						placeholder="Post title"
						[(ngModel)]="newPostObj.title"
					/>
				</div>
				<div class="col-md-6">
					<app-location-selector
						[appliedClasses]="'form-control input-fields input-text'"
						[startLocation]="post?.location_title"
						[locationChanged]="locationChanged"
						[placeholder]="'Location'"
					></app-location-selector>
				</div>
				<div class="col-md-12 mt-2">
					<textarea
						class="form-control input-fields input-textarea"
						placeholder="Description"
						rows="5"
						[(ngModel)]="newPostObj.text"
					></textarea>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-4">
					<div ngbDropdown class="d-block datepicker-dropdown" (openChange)="toggled($event, 1)">
						<button
							type="button"
							class="btn btn-block btn-outline-primary post-selector-button"
							id="dropdownForm2"
							ngbDropdownToggle
						>
							<img src="assets/icons/invite-peoples.svg" /> Deedbuds
							<ng-container *ngIf="deedbudsSelected.length > 0"
								>({{ deedbudsSelected.length }})</ng-container
							>
						</button>
						<div ngbDropdownMenu aria-labelledby="dropdownForm2">
							<gpe-create-post-user-selector
								[getSearch]="deedbudsSearch"
								[onChanged]="deedbudsChanged"
								[selectedUsers]="deedbudsSelected"
								[refNumber]="1"
								[buttonActivateText]="'Add'"
								[buttonDeactivateText]="'Remove'"
							></gpe-create-post-user-selector>
						</div>
					</div>
				</div>
				<div class="col-md-4 datepicker-dropdown dropdown">
					<button type="button" class="btn btn-block btn-outline-primary post-selector-button">
						<img src="assets/icons/sound-02.svg" /> Select sound
					</button>
				</div>
				<div class="col-md-4">
					<div class="row">
						<div class="col-md-12 mt-2">
							<label>
								<div
									class="custom-selector-holder"
									[class.custom-selector-holder-selected]="linkToEvent"
								>
									<img src="/assets/icons/check-02.svg" width="10" *ngIf="linkToEvent" />
								</div>
								<input type="checkbox" [(ngModel)]="linkToEvent" class="hidden-checkbox" /> Link to
								event</label
							>
						</div>
						<div class="col-md-12">
							<ng-container *ngIf="linkToEvent">
								<div
									ngbDropdown
									class="d-block datepicker-dropdown"
									(openChange)="toggledEventDropdown($event, 5)"
								>
									<button
										type="button"
										class="btn btn-block btn-outline-primary post-selector-button"
										id="dropdownForm5"
										ngbDropdownToggle
									>
										<img src="assets/icons/event.svg" /> Choose event
										<ng-container *ngIf="eventSelected !== undefined">(selected)</ng-container>
									</button>
									<div ngbDropdownMenu aria-labelledby="dropdownForm5">
										<gpe-create-post-event-selector
											[getSearch]="eventSearch"
											[onChanged]="eventChanged"
											[eventSelected]="eventSelected"
											[refNumber]="5"
											[buttonActivateText]="'Select'"
											[buttonDeactivateText]="'Remove'"
										></gpe-create-post-event-selector>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="row mt-3 mb-3">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-12">
					<div class="subtitle"><span>Post color</span></div>
				</div>
				<div class="col-md-10">
					<div class="colors">
						<a
							class="color"
							*ngFor="let color of bgColors"
							[style.background-color]="color"
							(click)="selectColor(color)"
						>
							<span><i class="fas fa-check" *ngIf="color == this.newPostObj.bgcolor"></i></span>
						</a>
					</div>
				</div>
				<div class="col-md-2"></div>
			</div>
		</div>
	</div>
	<div class="row mt-2">
		<div class="col-md-12">
			<div class="row">
				<div class="col-md-10">
					<a class="btn btn-success post-button-add d-block" (click)="addPost()">{{
						postId > 0 ? 'Edit post' : 'Add post'
					}}</a>
				</div>
				<div class="col-md-2">
					<a class="btn btn-secondary post-button-cancel d-block" (click)="closeModal()">Cancel</a>
				</div>
			</div>
		</div>
	</div>
</div>
