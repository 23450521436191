import { UserService } from 'src/app/core/services/user.service';
import { PostMediaType } from './../../../../../core/models/PostMediaType';
import { Component, Input, OnInit } from '@angular/core';

import { IPost, PostType } from '../../../../../core/models/IPost';

@Component({
	selector: 'gpe-event-general-frame',
	templateUrl: './post-general-event.component.html',
	styleUrls: ['./post-general-event.component.scss'],
})
export class PostGeneralEventComponent implements OnInit {
	PostType = PostType;
	PostMediaType = PostMediaType;

	myUserId: number = 0;

	@Input()
	post: IPost | undefined = undefined;

	@Input() showCommentsClicked!: () => void;

	@Input() showShareClicked!: () => void;

	@Input() reportPost!: () => void;
	@Input() reportUser!: () => void;
	@Input() hidePost!: () => void;
	@Input() blockUser!: () => void;
	@Input() editPost!: () => void;
	@Input() deletePost!: () => void;
	@Input() navigateToPost!: () => void;

	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();
	}
}
