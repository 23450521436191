import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/models/ApiResponse';
import { IComment, ICommentGetItem, ICommentGetList } from '../models/IComment';
import { IPostCommentLikeBatch, IPostCommentLikeBatchGet } from '../models/IPostCommentLikeBatch';
import { IPostLikeBatch, IPostLikeBatchGet } from '../models/IPostLikeBatch';
import { IProfile, IProfileGet } from '../models/IProfile';
import { IUserMinimal, IUserMinimalFollows } from '../models/IUserMinimal';

import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class PostCommentsService {
	private apiUrl = environment.apiURL;
	constructor(private authService: AuthService, private http: HttpClient) {}

	getComments(postId: number, page: number, limit = 15): Observable<IComment[]> {
		const start = (page - 1) * limit;

		const url = `${this.apiUrl}/post/comment/${postId}/?start=${start}&limit=${limit}`;

		return this.http.get<ApiResponse<ICommentGetList>>(url).pipe(
			map((res) => {
				return res.data.comments;
			}),
		);
	}

	deleteComment(postId: number, commentId: number): Observable<boolean> {
		const url = `${this.apiUrl}/post/comment/${postId}/item/${commentId}`;

		return this.http.delete<ApiResponse<boolean>>(url).pipe(
			map((res) => {
				return res.success == 1;
			}),
		);
	}

	editComment(postId: number, commentId: number, comment_text: string): Observable<IComment> {
		const url = `${this.apiUrl}/post/comment/${postId}/item/${commentId}`;

		const body = new FormData();
		body.append('comment_text', comment_text);

		return this.http.put<ApiResponse<ICommentGetItem>>(url, body).pipe(
			map((res) => {
				return res.data.comment;
			}),
		);
	}

	addComment(postId: number, parentCommentId: number, commentText: string): Observable<IComment> {
		const url = `${this.apiUrl}/post/comment/${postId}`;

		const body = new FormData();
		body.append('comment_text', commentText);
		body.append('comment_parent_id', parentCommentId.toString());

		return this.http.post<ApiResponse<ICommentGetItem>>(url, body).pipe(
			map((res) => {
				return res.data.comment;
			}),
		);
	}

	likeComment(commentId: number, nextState: boolean): Observable<IComment> {
		const url = `${this.apiUrl}/post/comment/like/${commentId}`;

		if (nextState) {
			return this.http.post<ApiResponse<ICommentGetItem>>(url, {}).pipe(
				map((res) => {
					return res.data.comment;
				}),
			);
		} else {
			return this.http.delete<ApiResponse<ICommentGetItem>>(url, {}).pipe(
				map((res) => {
					return res.data.comment;
				}),
			);
		}
	}

	//TODO:!
	getBlockedComments(postId: number, parentCommentId: number, commentText: string): Observable<IComment> {
		const url = `${this.apiUrl}/post/comment/${postId}`;

		const body = new FormData();
		body.append('comment_text', commentText);
		body.append('comment_parent_id', parentCommentId.toString());

		return this.http.post<ApiResponse<ICommentGetItem>>(url, body).pipe(
			map((res) => {
				return res.data.comment;
			}),
		);
	}

	getCommentsLikes(commentIds: number[]): Observable<IPostCommentLikeBatch[]> {
		const ids = commentIds.join(',');
		const url = `${this.apiUrl}/post/userlike/batch_post_comment?post_comment_ids=${ids}`;

		return this.http.get<ApiResponse<IPostCommentLikeBatchGet>>(url).pipe(
			map((res) => {
				return res.data.result;
			}),
		);
	}
}
