<div class="custom-search-box">
	<div class="search-firstline">
		<div class="search-icon"><img src="assets/icons/search-green.svg" /></div>
		<div class="search-input-box">
			<input
				type="text"
				class="form-control"
				[(ngModel)]="searchInput"
				(input)="inputChanged()"
				(keydown)="inputKeyDown($event)"
			/>
		</div>
		<a class="search-close" (click)="closeSearchbar()">
			<img src="assets/icons/fi_x-02.svg" />
		</a>
	</div>
	<div class="search-result">
		<ng-container *ngIf="!is_loading">
			<ng-container *ngIf="resultPost.length > 0">
				<div
					class="search-result-row cursor-pointer"
					*ngFor="let element of resultPost"
					(click)="navigateToPost(element.post_id)"
				>
					<ng-container *ngIf="element.post_type == PostType.Event">
						<div class="avatar-image">
							<ng-container *ngIf="element.medias.length > 0">
								<ng-container *ngFor="let actMedia of element.medias; index as i">
									<ng-container *ngIf="i < 1">
										<ng-container *ngIf="actMedia.type == PostMediaTypes.Image">
											<img class="avatar-image" [src]="actMedia.picture.file" />
										</ng-container>
									</ng-container>
								</ng-container>
							</ng-container>
						</div>

						{{ element.post_event_title }}
					</ng-container>
					<ng-container *ngIf="element.post_type == PostType.Picture">
						<div class="avatar-image">
							<ng-container *ngIf="element.medias.length > 0">
								<ng-container *ngFor="let actMedia of element.medias; index as i">
									<ng-container *ngIf="i < 1">
										<ng-container *ngIf="actMedia.type == PostMediaTypes.Image">
											<img class="avatar-image" [src]="actMedia.picture.file" />
										</ng-container>
									</ng-container>
								</ng-container>
							</ng-container>
						</div>

						{{ element.post_content_text }}
					</ng-container>
					<ng-container *ngIf="element.post_type == PostType.Sound">
						<div class="avatar-image"><i class="fas fa-music"></i></div>
						{{ element.post_content_title }}
					</ng-container>
					<ng-container *ngIf="element.post_type == PostType.Text">
						<div class="avatar-image"><i class="fas fa-car"></i></div>
						{{ element.post_content_title }}
					</ng-container>
					<ng-container *ngIf="element.post_type == PostType.Video">
						<div class="avatar-image"><i class="fas fa-car"></i></div>
						{{ element.post_content_title }}
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="resultUser.length > 0">
				<div
					class="search-result-row cursor-pointer"
					*ngFor="let element of resultUser"
					(click)="navigateToUser(element.user_id)"
				>
					<img class="avatar-image" [src]="element.data.user_avatar" /> {{ element.user_first_name }}
					{{ element.user_last_name }}
				</div>
			</ng-container>

			<!-- disabled in first release
      <a class="search-result-row"  *ngIf="searchInput != ''" (click)="jumpToSearch()">
        <div class="result-icon">
          <img src="assets/icons/search-green.svg">
        </div>
        <div class="result-more-text">
          <div class="result-more-text-content">
            <span>Detailed search:</span> {{searchInput}}
          </div>
        </div>
      </a> -->
		</ng-container>

		<div *ngIf="is_loading" class="text-center mt-2">
			<div class="loading-animation-container-sm">
				<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
			</div>
		</div>
	</div>
</div>
