import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnimationOptions } from 'ngx-lottie';

import { IPost, PostType } from '../../models/IPost';
import { IProfileMinimal } from '../../models/IProfileMinimal';
import { IUserMinimal } from '../../models/IUserMinimal';
import { PostMediaType } from '../../models/PostMediaType';
import { AuthService } from '../../services/auth.service';
import { NotificationsService } from '../../services/notifications.service';
import { PostService } from '../../services/post.service';
import { UserService } from '../../services/user.service';
import { UserProfileService } from '../../services/user-profile.service';

@Component({
	selector: 'gpe-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
	@Input()
	searchbarActivate: ((nextState: boolean) => void) | undefined;

	searchInput = '';
	debounceTimeout: any = undefined;

	resultUser: IProfileMinimal[] = [];
	resultPost: IPost[] = [];

	PostMediaTypes = PostMediaType;
	PostType = PostType;

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	is_loading = false;

	constructor(
		private authService: AuthService,
		private router: Router,
		private userProfileService: UserProfileService,
		private postService: PostService,
		private userService: UserService,
	) {}

	ngOnInit(): void {}

	ngOnDestroy() {
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}
	}

	inputKeyDown(e: Event) {
		if (e instanceof KeyboardEvent) {
			if (e.key == 'Enter') {
				this.jumpToSearch();
			}
		}
	}

	inputChanged() {
		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}
		this.is_loading = false;

		if (this.searchInput != '') {
			this.is_loading = true;
			const v = '' + this.searchInput;
			this.debounceTimeout = setTimeout(() => {
				this.resultPost = [];
				this.resultUser = [];

				const postPromise = new Promise((resolve) => {
					this.postService.getEventsBySearch(v, 1, 5).subscribe(
						(value) => {
							if (v == this.searchInput) {
								this.resultPost = value;
							}
						},
						(error) => {},
						() => {
							resolve(true);
						},
					);
				});

				const userPromise = new Promise((resolve) => {
					this.userService.searchUser(v, 1, 5).subscribe(
						(value) => {
							if (v == this.searchInput) {
								this.resultUser = value;
							}
						},
						(error) => {},
						() => {
							resolve(true);
						},
					);
				});

				Promise.all([postPromise, userPromise]).then((value) => {
					this.is_loading = false;
				});
			}, 2000);
		} else {
			this.resultPost = [];
			this.resultUser = [];
		}
	}

	closeSearchbar() {
		if (this.searchInput == '') {
			if (this.searchbarActivate) {
				this.searchbarActivate(false);
			}
		}
		this.resultPost = [];
		this.resultUser = [];
		this.searchInput = '';
	}

	jumpToSearch() {
		if (this.searchbarActivate) {
			this.searchbarActivate(false);
		}
		let inp = '' + this.searchInput;
		this.searchInput = '';
		this.router.navigate(['/profile/search'], { queryParams: { str: inp } });
	}

	navigateToUser(userId: number) {
		if (this.searchbarActivate) {
			this.searchbarActivate(false);
		}
		this.searchInput = '';
		this.router.navigate(['/users/' + userId]);
	}

	navigateToPost(postId: number) {
		if (this.searchbarActivate) {
			this.searchbarActivate(false);
		}
		this.searchInput = '';
		this.router.navigate(['/home/' + postId]);
	}
}
