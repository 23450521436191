<ng-container *ngIf="showComment && userFollowing">
	<ng-container *ngIf="showComment.user">
		<div class="row m-0">
			<div class="col-8 d-flex comment-profile">
				<div
					class="avatar-image cursor-pointer"
					[style.background-image]="'url(' + showComment.user.user_avatar + ')'"
					(click)="navigateToUser(showComment.user.user_id)"
				></div>
				<span class="profile-name cursor-pointer" (click)="navigateToUser(showComment.user.user_id)">
					{{ showComment.user.user_first_name }} {{ showComment.user.user_last_name }}
				</span>
				<a class="replybtn" *ngIf="mainComment" (click)="showReplyChg()">Válasz</a>
			</div>
			<div class="col-4 text-right">
				<span class="comment-date">
					{{ showComment.post_comment_date_added.toString() | dateAgoPipeMinimal }}
				</span>

				<div ngbDropdown class="d-inline-block">
					<div ngbDropdownToggle class="cursor-pointer" id="dropdownMore1">
						<img src="assets/icons/more-gray.svg" />
					</div>
					<div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMore1">
						<ng-container *ngIf="myUserId != showComment.user.user_id">
							<button
								*ngIf="showComment.user.is_followed"
								ngbDropdownItem
								(click)="userFollowing(showComment.user.user_id, false)"
							>
								<img src="assets/icons/kick-user.svg" /> Followed
							</button>
							<button
								*ngIf="!showComment.user.is_followed"
								ngbDropdownItem
								(click)="userFollowing(showComment.user.user_id, true)"
							>
								<img src="assets/icons/add-user.svg" /> Follow
							</button>
						</ng-container>
						<ng-container *ngIf="!is_editing">
							<button
								*ngIf="showComment.user.user_id == myUserId"
								ngbDropdownItem
								(click)="editComment(true)"
							>
								<img src="assets/icons/edit-room.svg" /> Edit comment
							</button>
							<button
								*ngIf="showComment.user.user_id == myUserId"
								ngbDropdownItem
								(click)="deleteComment()"
							>
								<img src="assets/icons/remove-room-list.svg" /> Remove comment
							</button>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="col-10">
				<p class="mt-4 text-justify" style="word-break: break-all">
					<ngx-dynamic-hooks
						*ngIf="!is_editing"
						[content]="showComment.post_comment_text"
						[context]="contextObj"
					>
					</ngx-dynamic-hooks>
				</p>
				<ng-container *ngIf="is_editing">
					<div class="text-left mb-2">
						<a class="btn btn-secondary" (click)="editComment(false)">Cancel editing</a>
					</div>
					<div class="input-group comment-input-box">
						<gpe-complex-text-input
							class="comment-input"
							[contentId]="showComment.post_comment_id"
							[inputStr]="showComment.post_comment_text"
							[inputUsers]="contextObj.userData"
						></gpe-complex-text-input>
						<a class="btn btn-secondary comment-input-btn" (click)="saveComment()">
							<img src="assets/icons/send-green.svg" alt="Send button icon" />
						</a>
					</div>
				</ng-container>
			</div>
			<div class="col-2">
				<div *ngIf="showComment.post_comment_liked" class="comment-like" (click)="likeComment(false)">
					<img src="assets/icons/hand-color.svg" alt="like button icon" />
					<span>{{ showComment.post_comment_like_count }}</span>
				</div>
				<div *ngIf="!showComment.post_comment_liked" class="comment-dislike" (click)="likeComment(true)">
					<img src="assets/icons/hand-black.svg" alt="like button icon" />
					<span>{{ showComment.post_comment_like_count }}</span>
				</div>
			</div>
			<div class="col-12 mb-3">
				<ng-container *ngIf="showComment.childs && showComment.childs.length > 0">
					<div *ngIf="!showReplyList" class="replay-button" (click)="showReplyList = !showReplyList">
						<img src="assets/icons/right-arrow-green.svg" />
						<span>{{ showComment.childs.length }} reply</span>
					</div>
					<div *ngIf="showReplyList" class="replay-button" (click)="showReplyList = !showReplyList">
						<img src="assets/icons/arrow-down-green.svg" />
						<span class="replay-is-open">{{ showComment.childs.length }} reply</span>
					</div>
					<div class="ml-reply pt-4 pb-4" *ngIf="showReplyList">
						<ng-container *ngFor="let children of showComment.childs; let i = index">
							<ng-container *ngIf="i < showCommentNum">
								<gpe-comment-item
									[comment]="children"
									[mainComment]="false"
									[userFollowing]="userFollowing"
								>
								</gpe-comment-item>
							</ng-container>
						</ng-container>
						<a
							*ngIf="showComment.childs.length > showCommentNum"
							class="show-more-comment cursor-pointer"
							(click)="showCommentNum = showCommentNum + 5"
						>
							Show more comment ({{ showComment.childs.length - showCommentNum }})
						</a>
					</div>
				</ng-container>
			</div>
			<div class="col-12" *ngIf="showReply">
				<div class="d-flex replybox">
					<img *ngIf="myUser" class="avatar-image" [src]="myUser.data.user_avatar" />

					<div class="input-group comment-input-box">
						<gpe-complex-text-input
							#textInputComponent
							class="comment-input"
							[contentId]="0"
						></gpe-complex-text-input>
						<a
							class="btn btn-secondary comment-input-btn"
							(click)="addNewComment(showComment.post_comment_id)"
						>
							<img src="assets/icons/send-green.svg" alt="Send button icon" />
						</a>
					</div>
				</div>
			</div>
			<div class="col-12">
				<hr class="mt-3" />
			</div>
		</div>
	</ng-container>
</ng-container>

<ng-template #reportModal let-modal>
	<a (click)="closeModal()" class="modal-close-button">X</a>
	<div class="m-4 report-container" *ngIf="report">
		<div class="text-center mt-2" *ngIf="report.is_loading">
			<div class="loading-animation-container"></div>
		</div>
		<ng-container *ngIf="!report.is_loading">
			<div class="title">{{ report.title }}</div>
			<div class="mb-2">{{ report.text }}</div>
			<textarea
				class="form-control input-fields input-textarea mb-3"
				placeholder=""
				rows="3"
				[(ngModel)]="report.userText"
				*ngIf="report.showUserText"
			></textarea>

			<div class="row mt-4">
				<div class="col-md-10">
					<a class="btn btn-success post-button-add d-block" (click)="report.accepted()">{{
						report.btnAccept
					}}</a>
				</div>
				<div class="col-md-2 p-0">
					<a class="btn btn-secondary post-button-cancel d-block" (click)="report.rejected()">{{
						report.btnReject
					}}</a>
				</div>
			</div>
		</ng-container>
	</div>
</ng-template>
