import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UserService } from '../services/user.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
	constructor(public userService: UserService) {}

	private apiUrl = environment.apiURL;

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let addAuthHeader = false;

		if (request.url.indexOf(this.apiUrl) === 0) {
			if (!request.headers.has('Authorization')) {
				addAuthHeader = true;
			}
		}

		if (addAuthHeader) {
			const userToken = this.userService.getUserToken();
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${environment.apiKey}|${userToken}`,
				},
			});
		}

		return next.handle(request);
	}
}
