<nav class="navbar navbar-expand-lg navbar-dark">
	<a class="navbar-brand" href="#">
		<img src="assets/images/gpe-logo-white.png" class="navbar-logo" />
	</a>
	<button
		class="navbar-toggler"
		type="button"
		data-toggle="collapse"
		data-target="#navbarNavDropdown"
		aria-controls="navbarNavDropdown"
		aria-expanded="false"
		aria-label="Toggle navigation"
	>
		<span class="navbar-toggler-icon"></span>
	</button>
	<div class="collapse navbar-collapse justify-content-center" id="navbarNavDropdown" *ngIf="currentUserId > 0">
		<div class="navbar-center-search" [style.margin-top]="centerButtonsInactivated ? '0' : '-700px'">
			<gpe-search-bar [searchbarActivate]="searchbarActivate"></gpe-search-bar>
		</div>
		<ul class="navbar-nav navbar-center-buttons" [style.margin-top]="centerButtonsInactivated ? '-700px' : '0'">
			<li class="nav-item active">
				<a class="nav-link" routerLink="/">
					<img src="assets/icons/home.svg" class="menu-icon" />
				</a>
			</li>
			<li class="nav-item active">
				<a class="nav-link" (click)="searchbarActivate(true)">
					<img src="assets/icons/search.svg" class="menu-icon" />
				</a>
			</li>
			<li class="nav-item active">
				<a class="nav-link" routerLink="/calendar">
					<img src="assets/icons/calendar.svg" class="menu-icon" />
				</a>
			</li>

			<li class="nav-item nav-item-plus">
				<a class="nav-link" (click)="openCreateNewPost(createPostModal, 0)">
					<img src="assets/icons/add.svg" class="menu-icon" />
				</a>
			</li>

			<li class="nav-item dropdown">
				<a
					class="nav-link"
					routerLink="/chat"
					href="#"
					id="navbarMessagesMenuLink"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					<div class="">
						<img src="assets/icons/message.svg" class="menu-icon" />
					</div>
				</a>
			</li>

			<li class="nav-item active">
				<a class="nav-link" routerLink="/profile"><img src="assets/icons/user.svg" class="menu-icon" /></a>
			</li>
			<li class="nav-item active">
				<a class="nav-link" routerLink="/rooms"><img src="assets/icons/users.svg" class="menu-icon" /></a>
			</li>
		</ul>
	</div>
	<div class="navbar-right-box justify-content-end" *ngIf="currentUserId == 0">
		<ul class="navbar-nav">
			<li class="nav-item active">
				<a class="nav-link text-white" routerLink="/">
					<img class="menu-icon" src="assets/icons/left-arrow.svg" /> Back</a
				>
			</li>
		</ul>
	</div>
	<div class="navbar-right-box justify-content-end" *ngIf="currentUserId > 0">
		<ul class="navbar-nav">
			<li class="nav-item dropdown">
				<div
					class="d-block datepicker-dropdown"
					ngbDropdown
					display="dynamic"
					placement="bottom-right"
					(openChange)="openChange($event)"
				>
					<a class="nav-link" id="navbarDropdown2" ngbDropdownToggle>
						<div class="menu-notify">
							<img src="assets/icons/bell.svg" class="menu-icon" />
							<span
								*ngIf="unreadNotifications > 0 && unreadNotifications < 10"
								class="badge bg-danger text-white"
							>
								{{ unreadNotifications }}
							</span>
							<span *ngIf="unreadNotifications > 10" class="badge bg-danger text-white">9+</span>
						</div>
					</a>

					<div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="notification-holder">
						<div class="notification-holder-arrow"></div>
						<div class="notify-content">
							<div class="row header-title-box">
								<div class="col-8">
									<span class="header-title">Notifications</span>
								</div>
								<div class="col-4 text-right" *ngIf="latestNotifications.length > 0">
									<a routerLink="/profile/notifications" class="header-btn">See all</a>
								</div>
							</div>
							<div class="not-have-notification" *ngIf="latestNotifications.length === 0">
								You have no notifications yet.
							</div>
							<div *ngFor="let notify of latestNotifications">
								<div class="notification-line" (click)="navigateToPost(notify.post.id)">
									<div class="d-flex">
										<div>
											<div
												class="avatar-image"
												[style.background-image]="'url(' + notify.sender.avatar + ')'"
												(click)="navigateToUser(notify.sender.id)"
											></div>
										</div>
										<div class="px-2 notification-text" (click)="navigateToNotify(notify)">
											<div class="notification-title">
												{{ notify.language_text }}
											</div>
											<div *ngIf="notify.post.title">
												{{ notify.post.title }}
											</div>
											<div
												*ngIf="
													notify.notify_confirm_type.has_buttons &&
													notify.notify_confirm_type.reacted === '0'
												"
											>
												<div *ngIf="notify.notify_confirm_type.reacted === '0'">
													<span
														*ngFor="let btn of notify.notify_confirm_type.buttons"
														class="btn btn-sm notify-reactbutton"
														[style.color]="btn.text_color"
														[style.background-color]="btn.bg_color"
														(click)="reactNotify(notify.id, btn.id)"
													>
														{{ btn.lang_text }}
													</span>
												</div>
											</div>
										</div>
										<div class="text-center">
											<span class="notification-date">
												{{
													(notify.sent_date_timestamp * 1000).toString() | dateAgoPipeMinimal
												}}
											</span>
											<br />
											<span
												*ngIf="notify.id > lastReadNotificationId"
												class="notify-status-unread"
											></span>
											<span
												*ngIf="notify.id <= lastReadNotificationId"
												class="notify-status-read"
											></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</li>
			<li class="nav-item dropdown" style="width: 120px">
				<div class="d-block datepicker-dropdown" ngbDropdown display="dynamic" placement="bottom-right">
					<a class="nav-link" id="navbarDropdown1" ngbDropdownToggle>
						<ng-container *ngIf="profile && profile.avatar">
							<img class="header-avatar-image" [src]="profile?.avatar" />
							{{ profile?.firstName }}
						</ng-container>
						<div class="text-center" *ngIf="!profile || (profile && !profile.avatar)">
							<div class="loading-animation-container-sm">
								<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
							</div>
						</div>
					</a>

					<div
						ngbDropdownMenu
						class="notification-holder profile-menu-holder"
						aria-labelledby="navbarDropdown1"
					>
						<div class="notification-holder-arrow"></div>
						<div class="notify-content p-2 pt-3">
							<a class="dropdown-item" translate>
								<div style="display: flex; align-items: center; gap: 4rem">
									<div (click)="toprofile()" style="display: flex; align-items: center">
										<img class="header-avatar-image" src="{{ profile?.avatar }}" />
										<div style="display: flex; flex-direction: column">
											<span class="profile-anchor" translate
												>profile.dropdownMenu.watchProfile</span
											>
											<span class="profile-name"
												>{{ profile?.firstName }} {{ profile?.lastName }}</span
											>
										</div>
									</div>

									<div class="profile-logout" (click)="logout()" translate>auth.logout</div>
								</div>
							</a>

							<ng-container
								*ngIf="
									$currentCompanies && mainProfile && mainProfile?.profile?.user_id != currentUserId
								"
							>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item">
									<div
										(click)="changeProfile(mainProfile)"
										style="display: flex; align-items: center"
									>
										<img
											*ngIf="mainProfile.profile.data"
											class="header-avatar-image"
											[src]="mainProfile.profile.data.user_avatar"
										/>
										<div style="display: flex; flex-direction: column">
											<span class="profile-anchor" translate
												>profile.dropdownMenu.switchProfile</span
											>
											<span class="profile-name">
												{{ mainProfile.profile.user_first_name }}
												{{ mainProfile.profile.user_last_name }}
											</span>
										</div>
									</div>
								</a>
							</ng-container>

							<ng-container *ngFor="let company of ($currentCompanies | async)?.tokens">
								<ng-container *ngIf="currentUserId != company.profile.user_id">
									<div class="dropdown-divider"></div>
									<a class="dropdown-item">
										<div
											(click)="changeProfile(company)"
											style="display: flex; align-items: center"
										>
											<img
												*ngIf="company.profile.data"
												class="header-avatar-image"
												[src]="company.profile.data.user_avatar"
											/>
											<div style="display: flex; flex-direction: column">
												<span class="profile-anchor" translate
													>profile.dropdownMenu.switchProfile</span
												>
												<span class="profile-name">{{ company.profile.user_first_name }}</span>
											</div>
										</div>
									</a>
								</ng-container>
							</ng-container>

							<div class="dropdown-divider"></div>
							<a class="dropdown-item" (click)="toeditprofile()" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.editProfile
							</a>

							<ng-container *ngIf="profile.user_type == IUserType.USER">
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" [routerLink]="['/profile/companies']" translate>
									<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
									profile.dropdownMenu.editCompanyprofiles
								</a>
							</ng-container>

							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/suggest-idea" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.suggestAnIdea
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/report-problem" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.reportProblem
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/pages/10-commitments" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.tenCommitments
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/pages/tutorial" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.tutorial
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/pages/faq" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.FAQ
							</a>

							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/pages/invite-friend" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.followAndInviteFriends
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/pages/privacy-policy" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.privacyPolicy
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/pages/terms-and-conditions" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.terms
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item" routerLink="/pages/about-us" translate>
								<img class="header-avatar-image" src="assets/icons/config-icon.svg" />
								profile.dropdownMenu.aboutUs
							</a>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</nav>
<div class="navbar-after"></div>

<ng-template #createPostModal let-modal>
	<gpe-create-post-base-component [postId]="newPostId" [modalRef]="modal"></gpe-create-post-base-component>
</ng-template>
