import { IPost } from 'src/app/core/models/IPost';
import { AnimationOptions } from 'ngx-lottie';
import { LocationService } from './../../../../../core/services/location.service';
import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { IEventCreate } from '../../../../../core/models/IEventCreate';
import { PostType } from '../../../../../core/models/IPost';
import { IProfileMinimal } from '../../../../../core/models/IProfileMinimal';
import { MediaUsageType } from '../../../../../core/models/MediaUsageType';
import { PostMediaType } from '../../../../../core/models/PostMediaType';
import { PostService } from '../../../../../core/services/post.service';
import { PostMediaService } from '../../../../../core/services/post-media.service';
import { UserService } from '../../../../../core/services/user.service';
import { CreateMedia } from '../../../../models/CreateMedia';
import { FileHandle } from '../../../../models/FileHandle';
import { MediaSelectorComponent } from '../common/media-selector/media-selector.component';
import { UserSelectorComponent } from '../common/user-selector/user-selector.component';

@Component({
	selector: 'gpe-create-post-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
	organizersSelected: IProfileMinimal[] = [];
	invitedSelected: IProfileMinimal[] = [];
	selectedMedias: FileHandle[] = [];
	selectedMediaDropdownIsOpen = false;

	@Input()
	postId!: number;

	@Input()
	post: IPost | undefined;

	submitted: boolean = false;
	errorColumns: string[] = [];

	locationObject: google.maps.places.PlaceResult | null = null;

	loadingAnimationOptions: AnimationOptions = {
		//path: '/assets/lottie/100958-loading-animation.json',
		path: '/assets/lottie/100931-loading.json',
	};

	newPostObj: IEventCreate = {
		title: '',
		text: '',
		location_id: 0,
		marked_users: '',
		post_type: PostType.Event,
		deedbuds: '',
		city_id: 0,
		has_media: false,
		event_online: false,
		event_date_from: undefined,
		event_date_to: undefined,
		event_max_people: 0,
		event_private: false,
		guestLimit: undefined,
	};

	@Input()
	bgColors!: string[];

	@Input()
	is_loading!: boolean;

	@Input()
	closeModal!: () => void;

	@Input()
	uploadAttachments!: (postId: number, postMedia: CreateMedia[]) => Promise<boolean>;

	@ViewChildren(UserSelectorComponent) userSelectors: QueryList<UserSelectorComponent> | undefined;
	@ViewChild(MediaSelectorComponent) mediaSelector: MediaSelectorComponent | undefined;
	@ViewChild('mediaSelectorDropdown') mediaSelectorDropdown: ElementRef | undefined;

	@ViewChild('dropdown1') dropdown1: ElementRef | undefined;
	@ViewChild('dropdown2') dropdown2: ElementRef | undefined;

	constructor(
		private userService: UserService,
		private postService: PostService,
		private postMediaService: PostMediaService,
		private router: Router,
		private locationService: LocationService,
	) {}

	async ngOnInit(): Promise<void> {
		if (this.postId > 0 && this.post) {
			this.is_loading = true;

			this.newPostObj = {
				title: this.post.post_event_title,
				text: this.post.post_event_text,
				location_id: this.post.location_id,
				marked_users: '',
				post_type: PostType.Event,
				deedbuds: '',
				city_id: 0,
				has_media: false,
				event_date_from: this.post.post_event_date_from_epoch
					? new Date(this.post.post_event_date_from_epoch)
					: undefined,
				event_date_to: this.post.post_event_date_to_epoch
					? new Date(this.post.post_event_date_to_epoch)
					: undefined,
				event_max_people: this.post.post_event_max_people,
				event_online: this.post.post_event_online == '1',
				event_private: this.post.post_event_private == '1',
				guestLimit: this.post.post_event_max_people > 0 ? 1 : 0,
			};

			if (this.post.medias && this.post.medias.length > 0) {
				let first = true;
				for (let media of this.post.medias) {
					let f: File | undefined;
					let fUrl = '';
					if (media.type == PostMediaType.Image) {
						fUrl = media.picture.file;
						f = await this.createFile(fUrl, 'image/jpeg', 'image.jpg');
					} else if (media.type == PostMediaType.Video) {
						if (media.video.thumbnail) {
							fUrl = media.video.thumbnail;
							f = await this.createFile(fUrl, 'image/jpeg', 'image.jpg');
						}
					}

					if (f != undefined) {
						let item: FileHandle = {
							file: f,
							id: media.media_id,
							url: fUrl,
							previewUrl: fUrl,
							primary: first,
						};
						this.selectedMedias.push(item);
						first = false;
					}
				}
			}

			for (let u of this.post.marked_users) {
				this.organizersSelected.push(this.userService.convertIUserMinimalToIProfileMinimal(u));
			}
			if (this.post.marked_users_invited) {
				for (let u of this.post.marked_users_invited) {
					this.organizersSelected.push(this.userService.convertIUserMinimalToIProfileMinimal(u));
				}
			}

			setTimeout(() => {
				this.is_loading = false;
			}, 1500);
		}
	}

	async createFile(url: string, metaType: string, name: string) {
		let response = await fetch(url);
		let data = await response.blob();
		let metadata = {
			type: metaType,
		};
		let file = new File([data], name, metadata);
		return file;
	}

	oraganizerSearch = (searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> => {
		return this.userService.searchPossibleOrganizer(searchStr, page, limit);
	};
	organizerChanged = (selected: IProfileMinimal[]): void => {
		this.organizersSelected = selected;
	};

	invitedSearch = (searchStr: string, page: number, limit = 15): Observable<IProfileMinimal[]> => {
		return this.userService.searchPossibleInvite(searchStr, page, limit);
	};
	invitedChanged = (selected: IProfileMinimal[]): void => {
		this.invitedSelected = selected;
	};

	toggled(event: boolean, refNumber: number) {
		if (this.userSelectors) {
			for (const e of this.userSelectors) {
				if (e.refNumber && e.refNumber == refNumber) {
					if (!event) {
						e.clearInput();
					} else {
						e.focusInput();
					}
				}
			}
		}
	}

	removeMedia(file: FileHandle) {
		this.selectedMedias = this.selectedMedias.filter((value) => value.url != file.url);
	}
	selectedMediasChanged = () => {
		this.selectedMediaDropdownIsOpen = false;
	};

	hasErrorColumn(column: string): boolean {
		return this.errorColumns.includes(column);
	}
	checkRequiredColumns() {
		this.errorColumns = [];
		if (this.newPostObj.title.length < 3) {
			this.errorColumns.push('title');
		}
		if (this.newPostObj.text.length < 3) {
			this.errorColumns.push('text');
		}
		if (
			!this.newPostObj.event_date_from ||
			this.newPostObj.event_date_from.getTime() < new Date().getTime() + 5 * 60 * 1000
		) {
			this.errorColumns.push('from');
		}

		if (
			!this.newPostObj.event_date_from ||
			!this.newPostObj.event_date_to ||
			this.newPostObj.event_date_to.getTime() < new Date().getTime() + 5 * 60 * 1000 ||
			this.newPostObj.event_date_to.getTime() < this.newPostObj.event_date_from.getTime()
		) {
			this.errorColumns.push('to');
		}

		if (
			this.newPostObj.guestLimit == 1 &&
			(!this.newPostObj.event_max_people || this.newPostObj.event_max_people < 1)
		) {
			this.errorColumns.push('max_people');
		}
	}

	async addEvent() {
		this.is_loading = true;

		this.submitted = true;
		this.checkRequiredColumns();
		if (this.errorColumns.length > 0) {
			this.is_loading = false;
			return;
		}

		if (this.locationObject != null) {
			let location = await this.locationService.getLocationId(this.locationObject);
			this.newPostObj.location_id = location.location_id;
		}

		if (this.selectedMedias.length > 0) {
			this.newPostObj.has_media = true;
		}

		if (this.postId > 0 && this.post) {
			this.postService.updateEvent(this.post.post_event_id, this.newPostObj).subscribe(
				(value) => {
					if (value && value.post_id > 0) {
						this._uploadAttachment(value.post_id);
					}
				},
				(error) => {
					this.is_loading = false;
				},
			);
		} else {
			this.postService.createEvent(this.newPostObj).subscribe(
				(value) => {
					if (value && value.post_id > 0) {
						this._uploadAttachment(value.post_id);
					}
				},
				(error) => {
					this.is_loading = false;
				},
			);
		}
	}
	private _uploadAttachment(post_id: number) {
		const medias: CreateMedia[] = [];
		for (const media of this.selectedMedias) {
			medias.push({
				file: media,
				usage_type: MediaUsageType.Post,
			});
		}
		this.uploadAttachments(post_id, medias).then((value1) => {
			this.closeModal();
			this.is_loading = false;
			this.router.navigate(['/profile'], { queryParams: { postId: post_id, updated: new Date().getTime() } });
		});
	}

	locationChanged = (newLocationObject: google.maps.places.PlaceResult | null) => {
		this.locationObject = newLocationObject;
	};

	openMediaSelector() {
		if (this.mediaSelector) {
			this.mediaSelector.openSelector();
		}
	}
	setToMain(file: FileHandle) {
		for (let m of this.selectedMedias) {
			m.primary = false;
		}
		file.primary = true;
	}
	changedDate(el: HTMLButtonElement) {
		if (!this.is_loading) {
			let refDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 5;
			if (this.newPostObj.event_date_to && this.newPostObj.event_date_to.getTime() > refDate) {
				el.click();
			}
		}
		this.checkRequiredColumns();
	}
}
