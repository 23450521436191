import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicContentChild, OnDynamicChanges, OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';

import { IComment } from '../../../core/models/IComment';
import { IProfileMinimal } from '../../../core/models/IProfileMinimal';
import { IUserMinimal } from '../../../core/models/IUserMinimal';

@Component({
	selector: 'gpe-user-inline',
	templateUrl: './user-inline.component.html',
	styleUrls: ['./user-inline.component.scss'],
})
export class UserInlineComponent implements OnInit {
	@Input()
	userId: number | undefined;

	@Input()
	userData: IUserMinimal[] | undefined;

	@Input()
	userFollowing: ((userId: number, nextState: boolean, comments?: IComment[] | undefined) => void) | undefined;

	actualUserData: IUserMinimal | undefined;

	constructor(private router: Router) {}

	ngOnInit(): void {
		if (this.userId && this.userData) {
			const filtered = this.userData.filter((value) => value.user_id == this.userId);
			if (filtered.length > 0) {
				this.actualUserData = filtered.pop();
			}
		}
	}

	closeTimeout: any;
	openTooltip(tooltip: any) {
		clearTimeout(this.closeTimeout);
		tooltip.open();
	}

	closeTooltip(tooltip: any) {
		this.closeTimeout = setTimeout(() => {
			tooltip.close();
		}, 1500);
	}

	navigateToUser(userId: number) {
		this.router.navigate(['/users/' + userId]);
	}
}
