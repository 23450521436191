<a (click)="closeModal()" class="modal-close-button">X</a>
<div class="m-3 overflow-auto">
	<div *ngIf="is_loading" class="text-center mt-5 mb-5">
		<div class="loading-animation-container">
			<ng-lottie [options]="loadingAnimationOptions"></ng-lottie>
		</div>
	</div>
	<div class="overflow-hidden" [style.max-height]="is_loading ? '0px' : '500px'">
		<div [lyDisplay]="'flex'">
			<span [lyFlex]="1"></span>
			<!-- Hidden input -->
			<input #_fileInput type="file" (change)="cropper.selectInputEvent($event)" accept="image/*" hidden />
		</div>

		<div *ngIf="ready" class="buttons-box">
			<button (click)="cropper.zoomIn()" class="btn btn-secondary action-button" title="Zoom in">
				<i class="fas fa-search-plus m-auto"></i>
			</button>
			<button (click)="cropper.zoomOut()" class="btn btn-secondary action-button" title="Zoom out">
				<i class="fas fa-search-minus m-auto"></i>
			</button>
			<button (click)="cropper.center()" class="btn btn-secondary action-button" title="Center">
				<i class="fas fa-dot-circle m-auto"></i>
			</button>
			<button (click)="cropper.rotate(-90)" class="btn btn-secondary action-button" title="Rotate">
				<i class="fas fa-sync-alt m-auto"></i>
			</button>
			<button (click)="cropper.fit()" class="btn btn-secondary action-button" title="Fit">
				<i class="fas fa-compress m-auto"></i>
			</button>
			<button (click)="cropper.clean()" class="btn btn-secondary action-button" title="Remove">
				<i class="fas fa-trash-alt m-auto"></i>
			</button>
		</div>

		<ly-img-cropper
			class="img-cropper-base"
			[config]="myConfig"
			[(scale)]="scale"
			(minScale)="minScale = $event"
			(ready)="ready = true"
			(cleaned)="ready = false"
			(cropped)="onCropped($event)"
			(loaded)="onLoaded($event)"
			(error)="onError($event)"
		>
			<img src="assets/icons/hand-grey.svg" />
			<div style="margin-left: 10%">
				<div>Drag and drop image</div>
				<div class="click-post">Or just click <span>here</span></div>
			</div>
		</ly-img-cropper>

		<ng-container *ngIf="ready">
			<br />
			<div class="mt-2 mb-4 text-center">
				<span *ngIf="ready" (click)="cropper.crop()" class="btn save-button btn-primary cursor-pointer"
					>Use image as profile picture</span
				>
				<span *ngIf="ready" (click)="closeModal()" class="btn btn-secondary cursor-pointer">Cancel</span>
			</div>
		</ng-container>
	</div>
</div>
