import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/services/auth.service';
import { ApiResponse } from '../../../shared/models/ApiResponse';
import { IStaticPage } from '../models/IStaticPage';
import { IStaticPageList } from '../models/IStaticPageList';

@Injectable({
	providedIn: 'root',
})
export class StaticPageService {
	private apiUrl = environment.apiURL;
	constructor(private authService: AuthService, private http: HttpClient) {}

	getPagesById(Id: number): Observable<IStaticPage> {
		return new Observable((observer) => {
			this.getAllPages().subscribe((value) => {
				let found = false;
				for (const page of value.data.static_pages) {
					if (page.id === Id) {
						found = true;
						observer.next(page);
					}
				}
				if (!found) {
					observer.error('invalid_id');
				}
			});
		});
	}

	getAllPages(): Observable<ApiResponse<IStaticPageList>> {
		return this.http.get<any>(`${this.apiUrl}/utility/static_page?page=1&limit=50`).pipe(
			map((res) => {
				return res;
			}),
		);
	}
}
