import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

import { FirebaseService } from '../../modules/chat/services/firebase.service';
import { INotification } from '../models/INotification';
import { AuthService } from '../services/auth.service';
import { NotificationsService } from '../services/notifications.service';
import { UserProfileService } from '../services/user-profile.service';

import { ILoginCompanyTokens, ILoginResponse } from './../../modules/authentication/models/ILoginResponse';
import { IUserType } from './../models/IUserBase';
import { PostService } from './../services/post.service';
import { UserService } from './../services/user.service';

@Component({
	selector: 'gpe-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private router: Router,
		private notificationsService: NotificationsService,
		private userProfileService: UserProfileService,
		private userService: UserService,
		private postService: PostService,
		private modalService: NgbModal,
		private socialAuthService: SocialAuthService,
		private firebaseService: FirebaseService,
	) {
		this.$currentCompanies = this.authService.currentCompanies;
	}

	loadingAnimationOptions: AnimationOptions = {
		path: '/assets/lottie/100958-loading-animation.json',
		//path: '/assets/lottie/100931-loading.json',
	};

	centerButtonsInactivated = false;

	currentUserId = 0;
	profile: any = {};
	$currentCompanies: Observable<ILoginCompanyTokens>;
	mainProfile: ILoginResponse | undefined;
	IUserType = IUserType;

	notificationsIntervalChecker: any;
	@ViewChild('createPostModal', { read: TemplateRef }) createPostModal!: TemplateRef<any>;
	newPostId = 0;

	ngOnInit(): void {
		this.postService.openNewPostModal.subscribe((value) => {
			this.openCreateNewPost(this.createPostModal, value);
		});
		this.currentUserId = this.userService.getLoginedUserId();
		this.$currentCompanies.subscribe((val) => {
			this.mainProfile = val.mainProfile;
		});
		this.userProfileService.getProfile().subscribe((res) => {
			this.profile.avatar = res.data.user_avatar;
			this.profile.firstName = res.user_first_name;
			this.profile.lastName = res.user_last_name;
			this.profile.user_type = res.user_type;
			this.profile.user_id = res.user_id;
		});

		if (this.notificationsIntervalChecker) {
			clearInterval(this.notificationsIntervalChecker);
		}

		this.notificationsIntervalChecker = setInterval(() => {
			this.loadNotifications();
		}, 30000);

		this.loadNotifications();
	}

	ngOnDestroy() {
		clearInterval(this.notificationsIntervalChecker);
	}

	latestNotifications: INotification[] = [];
	unreadNotifications = 0;
	lastReadNotificationId = 0;
	loadNotifications() {
		this.notificationsService.getNotifications(1).subscribe((value) => {
			const lastId = (this.lastReadNotificationId = this.notificationsService.getLastReadNotificationId());
			let unread = 0;
			for (const v of value) {
				if (v.id > lastId) {
					unread++;
				}
			}
			this.latestNotifications = value;
			this.unreadNotifications = unread;
		});
	}
	openChange(param: boolean) {
		if (param) {
			let maxId = 0;
			for (const v of this.latestNotifications) {
				if (v.id > maxId) {
					maxId = v.id;
				}
			}
			this.notificationsService.setLastReadNotificationId(maxId);
		} else {
			this.loadNotifications();
		}
	}
	reactNotify(notificationId: number, buttonId: number) {
		this.notificationsService.reactForNotification(notificationId, buttonId).subscribe((value) => {
			if (value.success) {
				this.loadNotifications();
			} else {
				Swal.fire({
					icon: 'error',
					title: 'TODO: FAILED TO RESPONSE',
				});
			}
		});
	}
	navigateToUser(userId: number) {
		this.router.navigate(['/users/' + userId]);
	}
	navigateToNotify(notify: INotification) {
		if (notify.post && notify.event && notify.event.id > 0) {
			//TODO:
			//this.router.navigate(['/calendar' /*+ notify.post.id*/]);
			this.router.navigate(['/home/' + notify.post.id]);
			return;
		}
		if (notify.comment && notify.post && notify.comment.id > 0) {
			this.router.navigate(['/home/' + notify.post.id], { queryParams: { toComment: notify.comment.id } });
			return;
		}
		if (notify.post && notify.post.id > 0) {
			this.router.navigate(['/home/' + notify.post.id]);
			return;
		}
		if (notify.sender && notify.sender.id > 0) {
			this.router.navigate(['/users/' + notify.sender.id]);
			return;
		}
	}

	navigateToPost(postId: number): void {
		this.router.navigate([`/home/${postId}`]);
	}

	toprofile() {
		if (this.profile) {
			if (this.profile.user_type == IUserType.COMPANY) {
				this.router.navigate(['/company/' + this.profile.user_id]);
				return;
			} else {
				this.router.navigate(['/profile']);
			}
		}
	}

	toeditprofile() {
		if (this.profile) {
			if (this.profile.user_type == IUserType.COMPANY) {
				this.router.navigate(['/company/' + this.profile.user_id + '/edit']);
				return;
			} else {
				this.router.navigate(['/profile/edit']);
			}
		}
	}

	logout() {
		this.socialAuthService.signOut();
		this.authService.logout().subscribe(
			(v) => {},
			(e) => {},
			() => {
				setTimeout(() => {
					this.firebaseService.logout();
					this.router.navigate(['/login']);
				}, 500);
			},
		);
	}
	changeProfile(login: ILoginResponse) {
		this.authService.changeProfile(login);
	}
	openCreateNewPost(content: any, newPostId: number) {
		this.newPostId = newPostId;
		this.modalService.open(content, {
			backdropClass: 'xlmodal-backdrop-style',
			size: 'xl',
			centered: true,
			scrollable: false,
			modalDialogClass: 'xlmodal-class',
			beforeDismiss: () => {
				return false;
			},
		});
	}

	searchbarActivate = (nextState: boolean): void => {
		this.centerButtonsInactivated = nextState;
	};
}
