import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DropdownInfoItem, DropdownInfos } from './company-table-dropdown.model';

@Component({
	selector: 'gpe-company-table-dropdown',
	templateUrl: './company-table-dropdown.component.html',
	styleUrls: ['./company-table-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyTableDropdownComponent implements OnInit {
	@Input() dropdownInfo: DropdownInfos = { items: [], title: '' };
	@Input() includeAllOption = true;

	@Output() filterEmit = new EventEmitter<string>();

	public dropdownItems: DropdownInfoItem[] = [];

	public selectedItem: DropdownInfoItem | undefined;

	ngOnInit(): void {
		this.dropdownItems = [];
		if (this.includeAllOption) {
			this.dropdownItems.push({ value: '', displayValue: `All(${this.dropdownInfo.items.length})` });
		}
		this.dropdownItems = this.dropdownItems.concat(this.dropdownInfo.items);
		this.selectedItem = this.dropdownItems[0] ?? { value: '', displayValue: 'No input' };
	}

	itemSelected(index: number): void {
		this.selectedItem = this.dropdownItems[index];
		if (this.includeAllOption) {
			this.filterEmit.emit(index > 0 ? this.selectedItem.value : '');
		} else {
			this.filterEmit.emit(this.selectedItem.value);
		}
	}
}
