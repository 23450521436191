import { Component, Input, OnInit } from '@angular/core';
import { Howl } from 'howler';
import { UserService } from 'src/app/core/services/user.service';

import { IPost } from '../../../../../core/models/IPost';
import { PostMediaType } from '../../../../../core/models/PostMediaType';

@Component({
	selector: 'gpe-post-type-audio',
	templateUrl: './post-type-audio.component.html',
	styleUrls: ['./post-type-audio.component.scss'],
})
export class PostTypeAudioComponent implements OnInit {
	PostMediaType = PostMediaType;

	myUserId = 0;
	@Input()
	post: IPost | undefined = undefined;

	@Input()
	showCommentsClicked!: () => void;

	@Input()
	showShareClicked!: () => void;

	@Input() public likePost: ((postId: number, nextState: boolean) => void) | undefined;

	@Input() reportPost!: () => void;
	@Input() reportUser!: () => void;
	@Input() hidePost!: () => void;
	@Input() blockUser!: () => void;
	@Input() editPost!: () => void;
	@Input() deletePost!: () => void;
	@Input() navigateToPost!: () => void;

	sound: Howl | null = null;
	percent = 0;
	seek = 0;
	updaterInterval: number | null = null;

	constructor(private userService: UserService) {}

	ngOnInit(): void {
		this.myUserId = this.userService.getLoginedUserId();

		if (this.post && this.post.medias) {
			const arr: string[] = [];
			for (const media of this.post?.medias) {
				if (media.type == PostMediaType.Sound) {
					arr.push(media.sound.file);
				}
			}
			this.sound = new Howl({
				src: arr,
				volume: 1.0,
			});
		}

		if (this.updaterInterval) {
			clearInterval(this.updaterInterval);
		}
		this.updaterInterval = setInterval(() => {
			if (this.sound) {
				this.percent = Math.ceil((this.sound.seek() / this.sound.duration()) * 100);
				this.seek = this.sound.seek();
			} else {
				this.percent = 0;
				this.seek = 0;
			}
		}, 50);
	}

	ngOnDestroy(): void {
		if (this.updaterInterval) {
			clearInterval(this.updaterInterval);
		}
	}
}
